.gray {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  &:hover {
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    filter: grayscale(0%);
  }
}

.image-overlay {
  position: relative;
  &:hover .gray {
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    filter: grayscale(0%);
  }
  &::after {
    content: '';
    display: block;
    width: 113px;
    height: 220px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.image-current {
  &::after {
    background-image: linear-gradient(96deg, rgba(216,216,216,0.00) 14%, rgba(255,218,230,0.79) 89%);
  }
}
.image-past {
  &::after {
    background-image: linear-gradient(96deg, rgba(216,216,216,0.00) 14%, rgba(238,255,218,0.79) 89%);
  }
}


.gallery {
  display: flex;
  flex-wrap: wrap;
  margin: get_vw(-2);
  padding: get_vw(20) 0;
  @include mq(md) {
    margin: get_vw(20, $viewport-md) get_vw(-2, $viewport-md);
    padding: get_vw(20, $viewport-md) 0;
  }
  @include mq(sm) {
    margin: get_vw(40, $viewport-sm) get_vw(-4, $viewport-sm);
    padding: get_vw(40, $viewport-sm) 0;
  }
  &-item {
    padding: get_vw(2);
    width: get_vw(176);
    height: get_vw(176);
    @include mq(md) {
      padding: get_vw(2, $viewport-md);
      width: get_vw(160, $viewport-md);
      height: get_vw(160, $viewport-md);
    }
    @include mq(sm) {
      padding: get_vw(4, $viewport-sm);
      width: get_vw(337, $viewport-sm);
      height: get_vw(337, $viewport-sm);
    }
    &-image {
      background-position: center;
      background-size: cover;
      height: 100%;
      display: block;
    }
  }
}

.image {
  position: relative;
  &-caption {
    position: absolute;
    left: get_vw(3);
    bottom: get_vw(3);
    color: $color-text-default;
    text-shadow: 0px 0px 2px $color-text-inverse;
    font-size: get_vw(7);
    @include mq(md) {
      right: get_vw(3, $viewport-md);
      bottom: get_vw(3, $viewport-md);
      font-size: get_vw(7, $viewport-md);
    }
    @include mq(sm) {
      right: get_vw(4, $viewport-sm);
      bottom: get_vw(4, $viewport-sm);
      font-size: get_vw(14, $viewport-sm);
    }
  }
}
