

/* --------------------------
font-size
-------------------------- */
@function get_vw($size, $viewport:1200){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@mixin fts($font_size:10){
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}

/* --------------------------
media query
-------------------------- */
$breakpoints: (
  'sm': 'screen and (max-width: 767px)',
  'md': 'screen and (max-width: 960px)'
) !default;

@mixin mq($breakpoint: sm) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
