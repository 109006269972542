/* --------------------------
header
-------------------------- */

.header {
  width: 100%;
  @include mq(sm) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: $color-background-default;
  }

}

.header-inner {
  width: 100%;
  margin: get_vw(50) auto get_vw(30);
  padding:0 get_vw($padding-contents);
  display: flex;
  justify-content: space-between;
  @include mq(md) {
    margin: get_vw(50, $viewport-md) auto get_vw(30, $viewport-md);
    padding:0 get_vw($padding-contents, $viewport-md);
  }
  @include mq(sm) {
    margin: get_vw(30, $viewport-sm) auto;
    padding: 0 get_vw(40, $viewport-sm);
  }
  /* --------------------------
  ロゴ
  -------------------------- */
  .header-logo {
    .header-logo-img {
      width: get_vw(120);
      height: auto;
      @include mq(md) {
        width: get_vw(120, $viewport-md);
      }
      @include mq(sm) {
        height: get_vw(100, $viewport-sm);
        width: auto;
      }
    }
  }
  .header-nav {
    display: flex;
    @include mq(sm) {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: $color-background-inverse;
      color: $color-text-inverse;
      z-index: 100;
      padding-top: get_vw(100, $viewport-sm);
    }

  }
  .header-nav-others {
    margin-left: 3vw;
    @include mq(md) {
      margin-left: 3vw;
    }
    @include mq(sm) {
      margin-left: 0;
      text-align: center;
    }
  }
  .header-nav-others-list {
    margin: 0 0 get_vw(15);
    padding: 0;
    list-style: none;
    display: flex;
    @include mq(md) {
      margin: 0 0 get_vw(15, $viewport-md);
    }

    li {
      +li {
        margin-left: get_vw(15);
        @include mq(md) {
          margin-left: get_vw(15, $viewport-md);
        }
      }
      a {
        text-decoration: none;
      }
    }
  }
  /* --------------------------
  ハンバーガーメニューアイコン
  -------------------------- */
  .header-menu {
      display: none;
      @include mq(sm) {
          position: absolute;
          right: get_vw(40, $viewport-sm);
          top: get_vw(60, $viewport-sm);
          display: block;
          z-index: 1000;
      }
  }
  .header-menu-trigger,
  .header-menu-trigger span {
    display: inline-block;
    transition: all .4s;
    box-sizing: border-box;
  }
  .header-menu-trigger {
    position: relative;
    width: get_vw(44, $viewport-sm);
    height: get_vw(32, $viewport-sm);
  }
  .header-menu-trigger span {
    position: absolute;
    left: 0;
    width: 100%;
    height: get_vw(4, $viewport-sm);
    background-color: $color-text-default;
  }
  .header-menu-trigger span:nth-of-type(1) {
    top: 0;
  }
  .header-menu-trigger span:nth-of-type(2) {
    top: get_vw(14, $viewport-sm);
  }
  .header-menu-trigger span:nth-of-type(3) {
    bottom: 0;
  }
  .header-menu-trigger.active span:nth-of-type(1) {
    -webkit-transform: translateY(get_vw(14, $viewport-sm)) rotate(-45deg);
    transform: translateY(get_vw(14, $viewport-sm)) rotate(-45deg);
    background-color: $color-text-inverse;
  }
  .header-menu-trigger.active span:nth-of-type(2) {
    opacity: 0;
    background-color: $color-text-inverse;
  }
  .header-menu-trigger.active span:nth-of-type(3) {
    -webkit-transform: translateY(get_vw(-14, $viewport-sm)) rotate(45deg);
    transform: translateY(get_vw(-14, $viewport-sm)) rotate(45deg);
    background-color: $color-text-inverse;
  }
}
/* --------------------------
Gナビ
-------------------------- */
.nav {
  ul {
    border-left: get_vw(2) solid $color-border-default;
    list-style: none;
    margin: 0;
    padding: 0;
    @include mq(md) {
      border-left: get_vw(2) solid $color-border-default;
    }
    @include mq(sm) {
      border-left: 0;
    }
    li {
      padding-left: get_vw(17);
      line-height: 1;
      letter-spacing: 0.21;
      @include mq(md) {
        padding-left: get_vw(17, $viewport-md);
      }
      @include mq(sm) {
        padding-left: 0;
      }
      +li {
        margin-top: get_vw(18);
        @include mq(md) {
          margin-top: get_vw(18, $viewport-md);
        }
      }
      a {
        font-size: get_vw(15);
        text-decoration: none;
        @include mq(md) {
          font-size: get_vw(15, $viewport-md);
        }
        @include mq(sm) {
          font-size: get_vw(48, $viewport-sm);
          line-height: get_vw(68, $viewport-sm);
          color: $color-text-inverse;
          text-align: center;
          display: block;
        }
      }
    }
  }
}

@include mq(sm) {
  .apply-btn {
    color: $color-text-inverse;
    border-color: $color-border-inverse;
  }
}
