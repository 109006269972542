
.residents-gallery {
  display: flex;
  flex-wrap: wrap;
  margin: get_vw(-2);
  padding: get_vw(20) 0;
  @include mq(md) {
    margin: get_vw(20, $viewport-md) get_vw(-2, $viewport-md);
    padding: get_vw(20, $viewport-md) 0;
  }
  @include mq(sm) {
    margin: get_vw(40, $viewport-sm) get_vw(-4, $viewport-sm);
    padding: get_vw(40, $viewport-sm) 0;
  }
  &-item {
    padding: get_vw(2);
    width: get_vw(140);
    height: get_vw(140);
    @include mq(md) {
      padding: get_vw(2, $viewport-md);
      width: get_vw(128, $viewport-md);
      height: get_vw(128, $viewport-md);
    }
    @include mq(sm) {
      padding: get_vw(4, $viewport-sm);
      width: get_vw(226, $viewport-sm);
      height: get_vw(226, $viewport-sm);
    }
    &-image {
      background-position: center;
      background-size: cover;
      height: 100%;
    }
  }
}
