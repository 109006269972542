@charset "UTF-8";
/* --------------------------
font-size
-------------------------- */
/* --------------------------
media query
-------------------------- */
/* --------------------------
color
-------------------------- */
/* --------------------------
font-size
-------------------------- */
/* --------------------------
spacing
-------------------------- */
/* --------------------------
contents width
-------------------------- */
/* --------------------------
z-index order
-------------------------- */
/* --------------------------
viewport
-------------------------- */
/*! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Document (https://html.spec.whatwg.org/multipage/semantics.html#semantics)
   ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: border-box;
  /* 2 */
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */
}

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
  box-sizing: border-box;
  /* 1 */
  cursor: default;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
  height: 100%;
}

/* Sections (https://html.spec.whatwg.org/multipage/semantics.html#sections)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
  font-size: 14px;
  line-height: 1;
  height: 100%;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: .67em 0;
}

/* Grouping content (https://html.spec.whatwg.org/multipage/semantics.html#grouping-content)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in IE.
 */
figcaption,
figure,
main {
  /* 1 */
  display: block;
}

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Text-level semantics (https://html.spec.whatwg.org/multipage/semantics.html#text-level-semantics)
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ffff00;
  color: #000000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none;
}

/* Embedded content (https://html.spec.whatwg.org/multipage/embedded-content.html#embedded-content)
   ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Tabular data (https://html.spec.whatwg.org/multipage/tables.html#tables)
   ========================================================================== */
/**
 * Collapse border spacing
 */
table {
  border-collapse: collapse;
}

/* Forms (https://html.spec.whatwg.org/multipage/forms.html#forms)
   ========================================================================== */
/**
 * Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  margin: 0;
}

/**
 * Inherit styling in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  overflow: auto;
  /* 1 */
  resize: vertical;
  /* 2 */
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/* Interactive elements (https://html.spec.whatwg.org/multipage/forms.html#interactive-elements)
   ========================================================================== */
/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details,
menu {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Scripting (https://html.spec.whatwg.org/multipage/scripting.html#scripting-3)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* User interaction (https://html.spec.whatwg.org/multipage/interaction.html#editing)
   ========================================================================== */
/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation;
}

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

/* ARIA (https://w3c.github.io/html-aria/)
   ========================================================================== */
/**
 * Change the cursor on busy elements (opinionated).
 */
[aria-busy="true"] {
  cursor: progress;
}

/*
 * Change the cursor on control elements (opinionated).
 */
[aria-controls] {
  cursor: pointer;
}

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */
[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */
[aria-disabled] {
  cursor: default;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.6666666667vw;
}

.col-lg-1 {
  width: 8.3333333333%;
  padding: 1.6666666667vw;
}

.col-lg-2 {
  width: 16.6666666667%;
  padding: 1.6666666667vw;
}

.col-lg-3 {
  width: 25%;
  padding: 1.6666666667vw;
}

.col-lg-4 {
  width: 33.3333333333%;
  padding: 1.6666666667vw;
}

.col-lg-5 {
  width: 41.6666666667%;
  padding: 1.6666666667vw;
}

.col-lg-6 {
  width: 50%;
  padding: 1.6666666667vw;
}

.col-lg-7 {
  width: 58.3333333333%;
  padding: 1.6666666667vw;
}

.col-lg-8 {
  width: 66.6666666667%;
  padding: 1.6666666667vw;
}

.col-lg-9 {
  width: 75%;
  padding: 1.6666666667vw;
}

.col-lg-10 {
  width: 83.3333333333%;
  padding: 1.6666666667vw;
}

.col-lg-11 {
  width: 91.6666666667%;
  padding: 1.6666666667vw;
}

.col-lg-12 {
  width: 100%;
  padding: 1.6666666667vw;
}

@media screen and (max-width: 960px) {
  .row {
    margin: 0 -2.0833333333vw;
  }
  .col-lg-1 {
    width: 8.3333333333%;
    padding: 2.0833333333vw;
  }
  .col-md-1 {
    width: 8.3333333333%;
    padding: 2.0833333333vw;
  }
  .col-lg-2 {
    width: 16.6666666667%;
    padding: 2.0833333333vw;
  }
  .col-md-2 {
    width: 16.6666666667%;
    padding: 2.0833333333vw;
  }
  .col-lg-3 {
    width: 25%;
    padding: 2.0833333333vw;
  }
  .col-md-3 {
    width: 25%;
    padding: 2.0833333333vw;
  }
  .col-lg-4 {
    width: 33.3333333333%;
    padding: 2.0833333333vw;
  }
  .col-md-4 {
    width: 33.3333333333%;
    padding: 2.0833333333vw;
  }
  .col-lg-5 {
    width: 41.6666666667%;
    padding: 2.0833333333vw;
  }
  .col-md-5 {
    width: 41.6666666667%;
    padding: 2.0833333333vw;
  }
  .col-lg-6 {
    width: 50%;
    padding: 2.0833333333vw;
  }
  .col-md-6 {
    width: 50%;
    padding: 2.0833333333vw;
  }
  .col-lg-7 {
    width: 58.3333333333%;
    padding: 2.0833333333vw;
  }
  .col-md-7 {
    width: 58.3333333333%;
    padding: 2.0833333333vw;
  }
  .col-lg-8 {
    width: 66.6666666667%;
    padding: 2.0833333333vw;
  }
  .col-md-8 {
    width: 66.6666666667%;
    padding: 2.0833333333vw;
  }
  .col-lg-9 {
    width: 75%;
    padding: 2.0833333333vw;
  }
  .col-md-9 {
    width: 75%;
    padding: 2.0833333333vw;
  }
  .col-lg-10 {
    width: 83.3333333333%;
    padding: 2.0833333333vw;
  }
  .col-md-10 {
    width: 83.3333333333%;
    padding: 2.0833333333vw;
  }
  .col-lg-11 {
    width: 91.6666666667%;
    padding: 2.0833333333vw;
  }
  .col-md-11 {
    width: 91.6666666667%;
    padding: 2.0833333333vw;
  }
  .col-lg-12 {
    width: 100%;
    padding: 2.0833333333vw;
  }
  .col-md-12 {
    width: 100%;
    padding: 2.0833333333vw;
  }
}

@media screen and (max-width: 767px) {
  .row {
    margin: 0 -2.6666666667vw;
  }
  .col-lg-1 {
    width: 8.3333333333%;
    padding: 2.6666666667vw;
  }
  .col-md-1 {
    width: 8.3333333333%;
    padding: 2.6666666667vw;
  }
  .col-sm-1 {
    width: 8.3333333333%;
    padding: 2.6666666667vw;
  }
  .col-lg-2 {
    width: 16.6666666667%;
    padding: 2.6666666667vw;
  }
  .col-md-2 {
    width: 16.6666666667%;
    padding: 2.6666666667vw;
  }
  .col-sm-2 {
    width: 16.6666666667%;
    padding: 2.6666666667vw;
  }
  .col-lg-3 {
    width: 25%;
    padding: 2.6666666667vw;
  }
  .col-md-3 {
    width: 25%;
    padding: 2.6666666667vw;
  }
  .col-sm-3 {
    width: 25%;
    padding: 2.6666666667vw;
  }
  .col-lg-4 {
    width: 33.3333333333%;
    padding: 2.6666666667vw;
  }
  .col-md-4 {
    width: 33.3333333333%;
    padding: 2.6666666667vw;
  }
  .col-sm-4 {
    width: 33.3333333333%;
    padding: 2.6666666667vw;
  }
  .col-lg-5 {
    width: 41.6666666667%;
    padding: 2.6666666667vw;
  }
  .col-md-5 {
    width: 41.6666666667%;
    padding: 2.6666666667vw;
  }
  .col-sm-5 {
    width: 41.6666666667%;
    padding: 2.6666666667vw;
  }
  .col-lg-6 {
    width: 50%;
    padding: 2.6666666667vw;
  }
  .col-md-6 {
    width: 50%;
    padding: 2.6666666667vw;
  }
  .col-sm-6 {
    width: 50%;
    padding: 2.6666666667vw;
  }
  .col-lg-7 {
    width: 58.3333333333%;
    padding: 2.6666666667vw;
  }
  .col-md-7 {
    width: 58.3333333333%;
    padding: 2.6666666667vw;
  }
  .col-sm-7 {
    width: 58.3333333333%;
    padding: 2.6666666667vw;
  }
  .col-lg-8 {
    width: 66.6666666667%;
    padding: 2.6666666667vw;
  }
  .col-md-8 {
    width: 66.6666666667%;
    padding: 2.6666666667vw;
  }
  .col-sm-8 {
    width: 66.6666666667%;
    padding: 2.6666666667vw;
  }
  .col-lg-9 {
    width: 75%;
    padding: 2.6666666667vw;
  }
  .col-md-9 {
    width: 75%;
    padding: 2.6666666667vw;
  }
  .col-sm-9 {
    width: 75%;
    padding: 2.6666666667vw;
  }
  .col-lg-10 {
    width: 83.3333333333%;
    padding: 2.6666666667vw;
  }
  .col-md-10 {
    width: 83.3333333333%;
    padding: 2.6666666667vw;
  }
  .col-sm-10 {
    width: 83.3333333333%;
    padding: 2.6666666667vw;
  }
  .col-lg-11 {
    width: 91.6666666667%;
    padding: 2.6666666667vw;
  }
  .col-md-11 {
    width: 91.6666666667%;
    padding: 2.6666666667vw;
  }
  .col-sm-11 {
    width: 91.6666666667%;
    padding: 2.6666666667vw;
  }
  .col-lg-12 {
    width: 100%;
    padding: 2.6666666667vw;
  }
  .col-md-12 {
    width: 100%;
    padding: 2.6666666667vw;
  }
  .col-sm-12 {
    width: 100%;
    padding: 2.6666666667vw;
  }
}

.clearfix::after {
  content: "";
  clear: both;
  font-size: 0;
  height: 0;
  display: block;
  visibility: hidden;
}

.sp-only {
  display: initial;
}

@media screen and (max-width: 960px) {
  .sp-only {
    display: none;
  }
}

.pc-only {
  display: initial;
}

@media screen and (max-width: 767px) {
  .pc-only {
    display: none !important;
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/**
---
name: spacing
category: utilities/spacing
---

### margin paddingを強制的に上書きする際に利用します。

* 5px刻みで0〜100まで
* スマホとPCで異なる場合はクラス名にspを付けたものを追加する

```html
<div class="mt20 mr20 ml20 mb20 spmt20 spmr20 spml20 spmb20"></div>
<div class="pt20 pr20 pl20 pb20 sppt20 sppr20 sppl20 sppb20"></div>
```
 */
/*==================================================================
margin padding styles
===================================================================*/
.mt0 {
  margin-top: 0vw !important;
}

.mr0 {
  margin-right: 0vw !important;
}

.ml0 {
  margin-left: 0vw !important;
}

.mb0 {
  margin-bottom: 0vw !important;
}

.pt0 {
  padding-top: 0vw !important;
}

.pr0 {
  padding-right: 0vw !important;
}

.pl0 {
  padding-left: 0vw !important;
}

.pb0 {
  padding-bottom: 0vw !important;
}

.mt5 {
  margin-top: 0.4166666667vw !important;
}

.mr5 {
  margin-right: 0.4166666667vw !important;
}

.ml5 {
  margin-left: 0.4166666667vw !important;
}

.mb5 {
  margin-bottom: 0.4166666667vw !important;
}

.pt5 {
  padding-top: 0.4166666667vw !important;
}

.pr5 {
  padding-right: 0.4166666667vw !important;
}

.pl5 {
  padding-left: 0.4166666667vw !important;
}

.pb5 {
  padding-bottom: 0.4166666667vw !important;
}

.mt10 {
  margin-top: 0.8333333333vw !important;
}

.mr10 {
  margin-right: 0.8333333333vw !important;
}

.ml10 {
  margin-left: 0.8333333333vw !important;
}

.mb10 {
  margin-bottom: 0.8333333333vw !important;
}

.pt10 {
  padding-top: 0.8333333333vw !important;
}

.pr10 {
  padding-right: 0.8333333333vw !important;
}

.pl10 {
  padding-left: 0.8333333333vw !important;
}

.pb10 {
  padding-bottom: 0.8333333333vw !important;
}

.mt15 {
  margin-top: 1.25vw !important;
}

.mr15 {
  margin-right: 1.25vw !important;
}

.ml15 {
  margin-left: 1.25vw !important;
}

.mb15 {
  margin-bottom: 1.25vw !important;
}

.pt15 {
  padding-top: 1.25vw !important;
}

.pr15 {
  padding-right: 1.25vw !important;
}

.pl15 {
  padding-left: 1.25vw !important;
}

.pb15 {
  padding-bottom: 1.25vw !important;
}

.mt20 {
  margin-top: 1.6666666667vw !important;
}

.mr20 {
  margin-right: 1.6666666667vw !important;
}

.ml20 {
  margin-left: 1.6666666667vw !important;
}

.mb20 {
  margin-bottom: 1.6666666667vw !important;
}

.pt20 {
  padding-top: 1.6666666667vw !important;
}

.pr20 {
  padding-right: 1.6666666667vw !important;
}

.pl20 {
  padding-left: 1.6666666667vw !important;
}

.pb20 {
  padding-bottom: 1.6666666667vw !important;
}

.mt25 {
  margin-top: 2.0833333333vw !important;
}

.mr25 {
  margin-right: 2.0833333333vw !important;
}

.ml25 {
  margin-left: 2.0833333333vw !important;
}

.mb25 {
  margin-bottom: 2.0833333333vw !important;
}

.pt25 {
  padding-top: 2.0833333333vw !important;
}

.pr25 {
  padding-right: 2.0833333333vw !important;
}

.pl25 {
  padding-left: 2.0833333333vw !important;
}

.pb25 {
  padding-bottom: 2.0833333333vw !important;
}

.mt30 {
  margin-top: 2.5vw !important;
}

.mr30 {
  margin-right: 2.5vw !important;
}

.ml30 {
  margin-left: 2.5vw !important;
}

.mb30 {
  margin-bottom: 2.5vw !important;
}

.pt30 {
  padding-top: 2.5vw !important;
}

.pr30 {
  padding-right: 2.5vw !important;
}

.pl30 {
  padding-left: 2.5vw !important;
}

.pb30 {
  padding-bottom: 2.5vw !important;
}

.mt35 {
  margin-top: 2.9166666667vw !important;
}

.mr35 {
  margin-right: 2.9166666667vw !important;
}

.ml35 {
  margin-left: 2.9166666667vw !important;
}

.mb35 {
  margin-bottom: 2.9166666667vw !important;
}

.pt35 {
  padding-top: 2.9166666667vw !important;
}

.pr35 {
  padding-right: 2.9166666667vw !important;
}

.pl35 {
  padding-left: 2.9166666667vw !important;
}

.pb35 {
  padding-bottom: 2.9166666667vw !important;
}

.mt40 {
  margin-top: 3.3333333333vw !important;
}

.mr40 {
  margin-right: 3.3333333333vw !important;
}

.ml40 {
  margin-left: 3.3333333333vw !important;
}

.mb40 {
  margin-bottom: 3.3333333333vw !important;
}

.pt40 {
  padding-top: 3.3333333333vw !important;
}

.pr40 {
  padding-right: 3.3333333333vw !important;
}

.pl40 {
  padding-left: 3.3333333333vw !important;
}

.pb40 {
  padding-bottom: 3.3333333333vw !important;
}

.mt45 {
  margin-top: 3.75vw !important;
}

.mr45 {
  margin-right: 3.75vw !important;
}

.ml45 {
  margin-left: 3.75vw !important;
}

.mb45 {
  margin-bottom: 3.75vw !important;
}

.pt45 {
  padding-top: 3.75vw !important;
}

.pr45 {
  padding-right: 3.75vw !important;
}

.pl45 {
  padding-left: 3.75vw !important;
}

.pb45 {
  padding-bottom: 3.75vw !important;
}

.mt50 {
  margin-top: 4.1666666667vw !important;
}

.mr50 {
  margin-right: 4.1666666667vw !important;
}

.ml50 {
  margin-left: 4.1666666667vw !important;
}

.mb50 {
  margin-bottom: 4.1666666667vw !important;
}

.pt50 {
  padding-top: 4.1666666667vw !important;
}

.pr50 {
  padding-right: 4.1666666667vw !important;
}

.pl50 {
  padding-left: 4.1666666667vw !important;
}

.pb50 {
  padding-bottom: 4.1666666667vw !important;
}

.mt55 {
  margin-top: 4.5833333333vw !important;
}

.mr55 {
  margin-right: 4.5833333333vw !important;
}

.ml55 {
  margin-left: 4.5833333333vw !important;
}

.mb55 {
  margin-bottom: 4.5833333333vw !important;
}

.pt55 {
  padding-top: 4.5833333333vw !important;
}

.pr55 {
  padding-right: 4.5833333333vw !important;
}

.pl55 {
  padding-left: 4.5833333333vw !important;
}

.pb55 {
  padding-bottom: 4.5833333333vw !important;
}

.mt60 {
  margin-top: 5vw !important;
}

.mr60 {
  margin-right: 5vw !important;
}

.ml60 {
  margin-left: 5vw !important;
}

.mb60 {
  margin-bottom: 5vw !important;
}

.pt60 {
  padding-top: 5vw !important;
}

.pr60 {
  padding-right: 5vw !important;
}

.pl60 {
  padding-left: 5vw !important;
}

.pb60 {
  padding-bottom: 5vw !important;
}

.mt65 {
  margin-top: 5.4166666667vw !important;
}

.mr65 {
  margin-right: 5.4166666667vw !important;
}

.ml65 {
  margin-left: 5.4166666667vw !important;
}

.mb65 {
  margin-bottom: 5.4166666667vw !important;
}

.pt65 {
  padding-top: 5.4166666667vw !important;
}

.pr65 {
  padding-right: 5.4166666667vw !important;
}

.pl65 {
  padding-left: 5.4166666667vw !important;
}

.pb65 {
  padding-bottom: 5.4166666667vw !important;
}

.mt70 {
  margin-top: 5.8333333333vw !important;
}

.mr70 {
  margin-right: 5.8333333333vw !important;
}

.ml70 {
  margin-left: 5.8333333333vw !important;
}

.mb70 {
  margin-bottom: 5.8333333333vw !important;
}

.pt70 {
  padding-top: 5.8333333333vw !important;
}

.pr70 {
  padding-right: 5.8333333333vw !important;
}

.pl70 {
  padding-left: 5.8333333333vw !important;
}

.pb70 {
  padding-bottom: 5.8333333333vw !important;
}

.mt75 {
  margin-top: 6.25vw !important;
}

.mr75 {
  margin-right: 6.25vw !important;
}

.ml75 {
  margin-left: 6.25vw !important;
}

.mb75 {
  margin-bottom: 6.25vw !important;
}

.pt75 {
  padding-top: 6.25vw !important;
}

.pr75 {
  padding-right: 6.25vw !important;
}

.pl75 {
  padding-left: 6.25vw !important;
}

.pb75 {
  padding-bottom: 6.25vw !important;
}

.mt80 {
  margin-top: 6.6666666667vw !important;
}

.mr80 {
  margin-right: 6.6666666667vw !important;
}

.ml80 {
  margin-left: 6.6666666667vw !important;
}

.mb80 {
  margin-bottom: 6.6666666667vw !important;
}

.pt80 {
  padding-top: 6.6666666667vw !important;
}

.pr80 {
  padding-right: 6.6666666667vw !important;
}

.pl80 {
  padding-left: 6.6666666667vw !important;
}

.pb80 {
  padding-bottom: 6.6666666667vw !important;
}

.mt85 {
  margin-top: 7.0833333333vw !important;
}

.mr85 {
  margin-right: 7.0833333333vw !important;
}

.ml85 {
  margin-left: 7.0833333333vw !important;
}

.mb85 {
  margin-bottom: 7.0833333333vw !important;
}

.pt85 {
  padding-top: 7.0833333333vw !important;
}

.pr85 {
  padding-right: 7.0833333333vw !important;
}

.pl85 {
  padding-left: 7.0833333333vw !important;
}

.pb85 {
  padding-bottom: 7.0833333333vw !important;
}

.mt90 {
  margin-top: 7.5vw !important;
}

.mr90 {
  margin-right: 7.5vw !important;
}

.ml90 {
  margin-left: 7.5vw !important;
}

.mb90 {
  margin-bottom: 7.5vw !important;
}

.pt90 {
  padding-top: 7.5vw !important;
}

.pr90 {
  padding-right: 7.5vw !important;
}

.pl90 {
  padding-left: 7.5vw !important;
}

.pb90 {
  padding-bottom: 7.5vw !important;
}

.mt95 {
  margin-top: 7.9166666667vw !important;
}

.mr95 {
  margin-right: 7.9166666667vw !important;
}

.ml95 {
  margin-left: 7.9166666667vw !important;
}

.mb95 {
  margin-bottom: 7.9166666667vw !important;
}

.pt95 {
  padding-top: 7.9166666667vw !important;
}

.pr95 {
  padding-right: 7.9166666667vw !important;
}

.pl95 {
  padding-left: 7.9166666667vw !important;
}

.pb95 {
  padding-bottom: 7.9166666667vw !important;
}

.mt100 {
  margin-top: 8.3333333333vw !important;
}

.mr100 {
  margin-right: 8.3333333333vw !important;
}

.ml100 {
  margin-left: 8.3333333333vw !important;
}

.mb100 {
  margin-bottom: 8.3333333333vw !important;
}

.pt100 {
  padding-top: 8.3333333333vw !important;
}

.pr100 {
  padding-right: 8.3333333333vw !important;
}

.pl100 {
  padding-left: 8.3333333333vw !important;
}

.pb100 {
  padding-bottom: 8.3333333333vw !important;
}

@media screen and (max-width: 960px) {
  .mt0 {
    margin-top: 0vw !important;
  }
  .mr0 {
    margin-right: 0vw !important;
  }
  .ml0 {
    margin-left: 0vw !important;
  }
  .mb0 {
    margin-bottom: 0vw !important;
  }
  .pt0 {
    padding-top: 0vw !important;
  }
  .pr0 {
    padding-right: 0vw !important;
  }
  .pl0 {
    padding-left: 0vw !important;
  }
  .pb0 {
    padding-bottom: 0vw !important;
  }
  .mt5 {
    margin-top: 0.5208333333vw !important;
  }
  .mr5 {
    margin-right: 0.5208333333vw !important;
  }
  .ml5 {
    margin-left: 0.5208333333vw !important;
  }
  .mb5 {
    margin-bottom: 0.5208333333vw !important;
  }
  .pt5 {
    padding-top: 0.5208333333vw !important;
  }
  .pr5 {
    padding-right: 0.5208333333vw !important;
  }
  .pl5 {
    padding-left: 0.5208333333vw !important;
  }
  .pb5 {
    padding-bottom: 0.5208333333vw !important;
  }
  .mt10 {
    margin-top: 1.0416666667vw !important;
  }
  .mr10 {
    margin-right: 1.0416666667vw !important;
  }
  .ml10 {
    margin-left: 1.0416666667vw !important;
  }
  .mb10 {
    margin-bottom: 1.0416666667vw !important;
  }
  .pt10 {
    padding-top: 1.0416666667vw !important;
  }
  .pr10 {
    padding-right: 1.0416666667vw !important;
  }
  .pl10 {
    padding-left: 1.0416666667vw !important;
  }
  .pb10 {
    padding-bottom: 1.0416666667vw !important;
  }
  .mt15 {
    margin-top: 1.5625vw !important;
  }
  .mr15 {
    margin-right: 1.5625vw !important;
  }
  .ml15 {
    margin-left: 1.5625vw !important;
  }
  .mb15 {
    margin-bottom: 1.5625vw !important;
  }
  .pt15 {
    padding-top: 1.5625vw !important;
  }
  .pr15 {
    padding-right: 1.5625vw !important;
  }
  .pl15 {
    padding-left: 1.5625vw !important;
  }
  .pb15 {
    padding-bottom: 1.5625vw !important;
  }
  .mt20 {
    margin-top: 2.0833333333vw !important;
  }
  .mr20 {
    margin-right: 2.0833333333vw !important;
  }
  .ml20 {
    margin-left: 2.0833333333vw !important;
  }
  .mb20 {
    margin-bottom: 2.0833333333vw !important;
  }
  .pt20 {
    padding-top: 2.0833333333vw !important;
  }
  .pr20 {
    padding-right: 2.0833333333vw !important;
  }
  .pl20 {
    padding-left: 2.0833333333vw !important;
  }
  .pb20 {
    padding-bottom: 2.0833333333vw !important;
  }
  .mt25 {
    margin-top: 2.6041666667vw !important;
  }
  .mr25 {
    margin-right: 2.6041666667vw !important;
  }
  .ml25 {
    margin-left: 2.6041666667vw !important;
  }
  .mb25 {
    margin-bottom: 2.6041666667vw !important;
  }
  .pt25 {
    padding-top: 2.6041666667vw !important;
  }
  .pr25 {
    padding-right: 2.6041666667vw !important;
  }
  .pl25 {
    padding-left: 2.6041666667vw !important;
  }
  .pb25 {
    padding-bottom: 2.6041666667vw !important;
  }
  .mt30 {
    margin-top: 3.125vw !important;
  }
  .mr30 {
    margin-right: 3.125vw !important;
  }
  .ml30 {
    margin-left: 3.125vw !important;
  }
  .mb30 {
    margin-bottom: 3.125vw !important;
  }
  .pt30 {
    padding-top: 3.125vw !important;
  }
  .pr30 {
    padding-right: 3.125vw !important;
  }
  .pl30 {
    padding-left: 3.125vw !important;
  }
  .pb30 {
    padding-bottom: 3.125vw !important;
  }
  .mt35 {
    margin-top: 3.6458333333vw !important;
  }
  .mr35 {
    margin-right: 3.6458333333vw !important;
  }
  .ml35 {
    margin-left: 3.6458333333vw !important;
  }
  .mb35 {
    margin-bottom: 3.6458333333vw !important;
  }
  .pt35 {
    padding-top: 3.6458333333vw !important;
  }
  .pr35 {
    padding-right: 3.6458333333vw !important;
  }
  .pl35 {
    padding-left: 3.6458333333vw !important;
  }
  .pb35 {
    padding-bottom: 3.6458333333vw !important;
  }
  .mt40 {
    margin-top: 4.1666666667vw !important;
  }
  .mr40 {
    margin-right: 4.1666666667vw !important;
  }
  .ml40 {
    margin-left: 4.1666666667vw !important;
  }
  .mb40 {
    margin-bottom: 4.1666666667vw !important;
  }
  .pt40 {
    padding-top: 4.1666666667vw !important;
  }
  .pr40 {
    padding-right: 4.1666666667vw !important;
  }
  .pl40 {
    padding-left: 4.1666666667vw !important;
  }
  .pb40 {
    padding-bottom: 4.1666666667vw !important;
  }
  .mt45 {
    margin-top: 4.6875vw !important;
  }
  .mr45 {
    margin-right: 4.6875vw !important;
  }
  .ml45 {
    margin-left: 4.6875vw !important;
  }
  .mb45 {
    margin-bottom: 4.6875vw !important;
  }
  .pt45 {
    padding-top: 4.6875vw !important;
  }
  .pr45 {
    padding-right: 4.6875vw !important;
  }
  .pl45 {
    padding-left: 4.6875vw !important;
  }
  .pb45 {
    padding-bottom: 4.6875vw !important;
  }
  .mt50 {
    margin-top: 5.2083333333vw !important;
  }
  .mr50 {
    margin-right: 5.2083333333vw !important;
  }
  .ml50 {
    margin-left: 5.2083333333vw !important;
  }
  .mb50 {
    margin-bottom: 5.2083333333vw !important;
  }
  .pt50 {
    padding-top: 5.2083333333vw !important;
  }
  .pr50 {
    padding-right: 5.2083333333vw !important;
  }
  .pl50 {
    padding-left: 5.2083333333vw !important;
  }
  .pb50 {
    padding-bottom: 5.2083333333vw !important;
  }
  .mt55 {
    margin-top: 5.7291666667vw !important;
  }
  .mr55 {
    margin-right: 5.7291666667vw !important;
  }
  .ml55 {
    margin-left: 5.7291666667vw !important;
  }
  .mb55 {
    margin-bottom: 5.7291666667vw !important;
  }
  .pt55 {
    padding-top: 5.7291666667vw !important;
  }
  .pr55 {
    padding-right: 5.7291666667vw !important;
  }
  .pl55 {
    padding-left: 5.7291666667vw !important;
  }
  .pb55 {
    padding-bottom: 5.7291666667vw !important;
  }
  .mt60 {
    margin-top: 6.25vw !important;
  }
  .mr60 {
    margin-right: 6.25vw !important;
  }
  .ml60 {
    margin-left: 6.25vw !important;
  }
  .mb60 {
    margin-bottom: 6.25vw !important;
  }
  .pt60 {
    padding-top: 6.25vw !important;
  }
  .pr60 {
    padding-right: 6.25vw !important;
  }
  .pl60 {
    padding-left: 6.25vw !important;
  }
  .pb60 {
    padding-bottom: 6.25vw !important;
  }
  .mt65 {
    margin-top: 6.7708333333vw !important;
  }
  .mr65 {
    margin-right: 6.7708333333vw !important;
  }
  .ml65 {
    margin-left: 6.7708333333vw !important;
  }
  .mb65 {
    margin-bottom: 6.7708333333vw !important;
  }
  .pt65 {
    padding-top: 6.7708333333vw !important;
  }
  .pr65 {
    padding-right: 6.7708333333vw !important;
  }
  .pl65 {
    padding-left: 6.7708333333vw !important;
  }
  .pb65 {
    padding-bottom: 6.7708333333vw !important;
  }
  .mt70 {
    margin-top: 7.2916666667vw !important;
  }
  .mr70 {
    margin-right: 7.2916666667vw !important;
  }
  .ml70 {
    margin-left: 7.2916666667vw !important;
  }
  .mb70 {
    margin-bottom: 7.2916666667vw !important;
  }
  .pt70 {
    padding-top: 7.2916666667vw !important;
  }
  .pr70 {
    padding-right: 7.2916666667vw !important;
  }
  .pl70 {
    padding-left: 7.2916666667vw !important;
  }
  .pb70 {
    padding-bottom: 7.2916666667vw !important;
  }
  .mt75 {
    margin-top: 7.8125vw !important;
  }
  .mr75 {
    margin-right: 7.8125vw !important;
  }
  .ml75 {
    margin-left: 7.8125vw !important;
  }
  .mb75 {
    margin-bottom: 7.8125vw !important;
  }
  .pt75 {
    padding-top: 7.8125vw !important;
  }
  .pr75 {
    padding-right: 7.8125vw !important;
  }
  .pl75 {
    padding-left: 7.8125vw !important;
  }
  .pb75 {
    padding-bottom: 7.8125vw !important;
  }
  .mt80 {
    margin-top: 8.3333333333vw !important;
  }
  .mr80 {
    margin-right: 8.3333333333vw !important;
  }
  .ml80 {
    margin-left: 8.3333333333vw !important;
  }
  .mb80 {
    margin-bottom: 8.3333333333vw !important;
  }
  .pt80 {
    padding-top: 8.3333333333vw !important;
  }
  .pr80 {
    padding-right: 8.3333333333vw !important;
  }
  .pl80 {
    padding-left: 8.3333333333vw !important;
  }
  .pb80 {
    padding-bottom: 8.3333333333vw !important;
  }
  .mt85 {
    margin-top: 8.8541666667vw !important;
  }
  .mr85 {
    margin-right: 8.8541666667vw !important;
  }
  .ml85 {
    margin-left: 8.8541666667vw !important;
  }
  .mb85 {
    margin-bottom: 8.8541666667vw !important;
  }
  .pt85 {
    padding-top: 8.8541666667vw !important;
  }
  .pr85 {
    padding-right: 8.8541666667vw !important;
  }
  .pl85 {
    padding-left: 8.8541666667vw !important;
  }
  .pb85 {
    padding-bottom: 8.8541666667vw !important;
  }
  .mt90 {
    margin-top: 9.375vw !important;
  }
  .mr90 {
    margin-right: 9.375vw !important;
  }
  .ml90 {
    margin-left: 9.375vw !important;
  }
  .mb90 {
    margin-bottom: 9.375vw !important;
  }
  .pt90 {
    padding-top: 9.375vw !important;
  }
  .pr90 {
    padding-right: 9.375vw !important;
  }
  .pl90 {
    padding-left: 9.375vw !important;
  }
  .pb90 {
    padding-bottom: 9.375vw !important;
  }
  .mt95 {
    margin-top: 9.8958333333vw !important;
  }
  .mr95 {
    margin-right: 9.8958333333vw !important;
  }
  .ml95 {
    margin-left: 9.8958333333vw !important;
  }
  .mb95 {
    margin-bottom: 9.8958333333vw !important;
  }
  .pt95 {
    padding-top: 9.8958333333vw !important;
  }
  .pr95 {
    padding-right: 9.8958333333vw !important;
  }
  .pl95 {
    padding-left: 9.8958333333vw !important;
  }
  .pb95 {
    padding-bottom: 9.8958333333vw !important;
  }
  .mt100 {
    margin-top: 10.4166666667vw !important;
  }
  .mr100 {
    margin-right: 10.4166666667vw !important;
  }
  .ml100 {
    margin-left: 10.4166666667vw !important;
  }
  .mb100 {
    margin-bottom: 10.4166666667vw !important;
  }
  .pt100 {
    padding-top: 10.4166666667vw !important;
  }
  .pr100 {
    padding-right: 10.4166666667vw !important;
  }
  .pl100 {
    padding-left: 10.4166666667vw !important;
  }
  .pb100 {
    padding-bottom: 10.4166666667vw !important;
  }
}

@media screen and (max-width: 767px) {
  .spmt0 {
    margin-top: 0vw !important;
  }
  .spmr0 {
    margin-right: 0vw !important;
  }
  .spml0 {
    margin-left: 0vw !important;
  }
  .spmb0 {
    margin-bottom: 0vw !important;
  }
  .sppt0 {
    padding-top: 0vw !important;
  }
  .sppr0 {
    padding-right: 0vw !important;
  }
  .sppl0 {
    padding-left: 0vw !important;
  }
  .sppb0 {
    padding-bottom: 0vw !important;
  }
  .spmt5 {
    margin-top: 0.4166666667vw !important;
  }
  .spmr5 {
    margin-right: 0.4166666667vw !important;
  }
  .spml5 {
    margin-left: 0.4166666667vw !important;
  }
  .spmb5 {
    margin-bottom: 0.4166666667vw !important;
  }
  .sppt5 {
    padding-top: 0.4166666667vw !important;
  }
  .sppr5 {
    padding-right: 0.4166666667vw !important;
  }
  .sppl5 {
    padding-left: 0.4166666667vw !important;
  }
  .sppb5 {
    padding-bottom: 0.4166666667vw !important;
  }
  .spmt10 {
    margin-top: 0.8333333333vw !important;
  }
  .spmr10 {
    margin-right: 0.8333333333vw !important;
  }
  .spml10 {
    margin-left: 0.8333333333vw !important;
  }
  .spmb10 {
    margin-bottom: 0.8333333333vw !important;
  }
  .sppt10 {
    padding-top: 0.8333333333vw !important;
  }
  .sppr10 {
    padding-right: 0.8333333333vw !important;
  }
  .sppl10 {
    padding-left: 0.8333333333vw !important;
  }
  .sppb10 {
    padding-bottom: 0.8333333333vw !important;
  }
  .spmt15 {
    margin-top: 1.25vw !important;
  }
  .spmr15 {
    margin-right: 1.25vw !important;
  }
  .spml15 {
    margin-left: 1.25vw !important;
  }
  .spmb15 {
    margin-bottom: 1.25vw !important;
  }
  .sppt15 {
    padding-top: 1.25vw !important;
  }
  .sppr15 {
    padding-right: 1.25vw !important;
  }
  .sppl15 {
    padding-left: 1.25vw !important;
  }
  .sppb15 {
    padding-bottom: 1.25vw !important;
  }
  .spmt20 {
    margin-top: 1.6666666667vw !important;
  }
  .spmr20 {
    margin-right: 1.6666666667vw !important;
  }
  .spml20 {
    margin-left: 1.6666666667vw !important;
  }
  .spmb20 {
    margin-bottom: 1.6666666667vw !important;
  }
  .sppt20 {
    padding-top: 1.6666666667vw !important;
  }
  .sppr20 {
    padding-right: 1.6666666667vw !important;
  }
  .sppl20 {
    padding-left: 1.6666666667vw !important;
  }
  .sppb20 {
    padding-bottom: 1.6666666667vw !important;
  }
  .spmt25 {
    margin-top: 2.0833333333vw !important;
  }
  .spmr25 {
    margin-right: 2.0833333333vw !important;
  }
  .spml25 {
    margin-left: 2.0833333333vw !important;
  }
  .spmb25 {
    margin-bottom: 2.0833333333vw !important;
  }
  .sppt25 {
    padding-top: 2.0833333333vw !important;
  }
  .sppr25 {
    padding-right: 2.0833333333vw !important;
  }
  .sppl25 {
    padding-left: 2.0833333333vw !important;
  }
  .sppb25 {
    padding-bottom: 2.0833333333vw !important;
  }
  .spmt30 {
    margin-top: 2.5vw !important;
  }
  .spmr30 {
    margin-right: 2.5vw !important;
  }
  .spml30 {
    margin-left: 2.5vw !important;
  }
  .spmb30 {
    margin-bottom: 2.5vw !important;
  }
  .sppt30 {
    padding-top: 2.5vw !important;
  }
  .sppr30 {
    padding-right: 2.5vw !important;
  }
  .sppl30 {
    padding-left: 2.5vw !important;
  }
  .sppb30 {
    padding-bottom: 2.5vw !important;
  }
  .spmt35 {
    margin-top: 2.9166666667vw !important;
  }
  .spmr35 {
    margin-right: 2.9166666667vw !important;
  }
  .spml35 {
    margin-left: 2.9166666667vw !important;
  }
  .spmb35 {
    margin-bottom: 2.9166666667vw !important;
  }
  .sppt35 {
    padding-top: 2.9166666667vw !important;
  }
  .sppr35 {
    padding-right: 2.9166666667vw !important;
  }
  .sppl35 {
    padding-left: 2.9166666667vw !important;
  }
  .sppb35 {
    padding-bottom: 2.9166666667vw !important;
  }
  .spmt40 {
    margin-top: 3.3333333333vw !important;
  }
  .spmr40 {
    margin-right: 3.3333333333vw !important;
  }
  .spml40 {
    margin-left: 3.3333333333vw !important;
  }
  .spmb40 {
    margin-bottom: 3.3333333333vw !important;
  }
  .sppt40 {
    padding-top: 3.3333333333vw !important;
  }
  .sppr40 {
    padding-right: 3.3333333333vw !important;
  }
  .sppl40 {
    padding-left: 3.3333333333vw !important;
  }
  .sppb40 {
    padding-bottom: 3.3333333333vw !important;
  }
  .spmt45 {
    margin-top: 3.75vw !important;
  }
  .spmr45 {
    margin-right: 3.75vw !important;
  }
  .spml45 {
    margin-left: 3.75vw !important;
  }
  .spmb45 {
    margin-bottom: 3.75vw !important;
  }
  .sppt45 {
    padding-top: 3.75vw !important;
  }
  .sppr45 {
    padding-right: 3.75vw !important;
  }
  .sppl45 {
    padding-left: 3.75vw !important;
  }
  .sppb45 {
    padding-bottom: 3.75vw !important;
  }
  .spmt50 {
    margin-top: 4.1666666667vw !important;
  }
  .spmr50 {
    margin-right: 4.1666666667vw !important;
  }
  .spml50 {
    margin-left: 4.1666666667vw !important;
  }
  .spmb50 {
    margin-bottom: 4.1666666667vw !important;
  }
  .sppt50 {
    padding-top: 4.1666666667vw !important;
  }
  .sppr50 {
    padding-right: 4.1666666667vw !important;
  }
  .sppl50 {
    padding-left: 4.1666666667vw !important;
  }
  .sppb50 {
    padding-bottom: 4.1666666667vw !important;
  }
  .spmt55 {
    margin-top: 4.5833333333vw !important;
  }
  .spmr55 {
    margin-right: 4.5833333333vw !important;
  }
  .spml55 {
    margin-left: 4.5833333333vw !important;
  }
  .spmb55 {
    margin-bottom: 4.5833333333vw !important;
  }
  .sppt55 {
    padding-top: 4.5833333333vw !important;
  }
  .sppr55 {
    padding-right: 4.5833333333vw !important;
  }
  .sppl55 {
    padding-left: 4.5833333333vw !important;
  }
  .sppb55 {
    padding-bottom: 4.5833333333vw !important;
  }
  .spmt60 {
    margin-top: 5vw !important;
  }
  .spmr60 {
    margin-right: 5vw !important;
  }
  .spml60 {
    margin-left: 5vw !important;
  }
  .spmb60 {
    margin-bottom: 5vw !important;
  }
  .sppt60 {
    padding-top: 5vw !important;
  }
  .sppr60 {
    padding-right: 5vw !important;
  }
  .sppl60 {
    padding-left: 5vw !important;
  }
  .sppb60 {
    padding-bottom: 5vw !important;
  }
  .spmt65 {
    margin-top: 5.4166666667vw !important;
  }
  .spmr65 {
    margin-right: 5.4166666667vw !important;
  }
  .spml65 {
    margin-left: 5.4166666667vw !important;
  }
  .spmb65 {
    margin-bottom: 5.4166666667vw !important;
  }
  .sppt65 {
    padding-top: 5.4166666667vw !important;
  }
  .sppr65 {
    padding-right: 5.4166666667vw !important;
  }
  .sppl65 {
    padding-left: 5.4166666667vw !important;
  }
  .sppb65 {
    padding-bottom: 5.4166666667vw !important;
  }
  .spmt70 {
    margin-top: 5.8333333333vw !important;
  }
  .spmr70 {
    margin-right: 5.8333333333vw !important;
  }
  .spml70 {
    margin-left: 5.8333333333vw !important;
  }
  .spmb70 {
    margin-bottom: 5.8333333333vw !important;
  }
  .sppt70 {
    padding-top: 5.8333333333vw !important;
  }
  .sppr70 {
    padding-right: 5.8333333333vw !important;
  }
  .sppl70 {
    padding-left: 5.8333333333vw !important;
  }
  .sppb70 {
    padding-bottom: 5.8333333333vw !important;
  }
  .spmt75 {
    margin-top: 6.25vw !important;
  }
  .spmr75 {
    margin-right: 6.25vw !important;
  }
  .spml75 {
    margin-left: 6.25vw !important;
  }
  .spmb75 {
    margin-bottom: 6.25vw !important;
  }
  .sppt75 {
    padding-top: 6.25vw !important;
  }
  .sppr75 {
    padding-right: 6.25vw !important;
  }
  .sppl75 {
    padding-left: 6.25vw !important;
  }
  .sppb75 {
    padding-bottom: 6.25vw !important;
  }
  .spmt80 {
    margin-top: 6.6666666667vw !important;
  }
  .spmr80 {
    margin-right: 6.6666666667vw !important;
  }
  .spml80 {
    margin-left: 6.6666666667vw !important;
  }
  .spmb80 {
    margin-bottom: 6.6666666667vw !important;
  }
  .sppt80 {
    padding-top: 6.6666666667vw !important;
  }
  .sppr80 {
    padding-right: 6.6666666667vw !important;
  }
  .sppl80 {
    padding-left: 6.6666666667vw !important;
  }
  .sppb80 {
    padding-bottom: 6.6666666667vw !important;
  }
  .spmt85 {
    margin-top: 7.0833333333vw !important;
  }
  .spmr85 {
    margin-right: 7.0833333333vw !important;
  }
  .spml85 {
    margin-left: 7.0833333333vw !important;
  }
  .spmb85 {
    margin-bottom: 7.0833333333vw !important;
  }
  .sppt85 {
    padding-top: 7.0833333333vw !important;
  }
  .sppr85 {
    padding-right: 7.0833333333vw !important;
  }
  .sppl85 {
    padding-left: 7.0833333333vw !important;
  }
  .sppb85 {
    padding-bottom: 7.0833333333vw !important;
  }
  .spmt90 {
    margin-top: 7.5vw !important;
  }
  .spmr90 {
    margin-right: 7.5vw !important;
  }
  .spml90 {
    margin-left: 7.5vw !important;
  }
  .spmb90 {
    margin-bottom: 7.5vw !important;
  }
  .sppt90 {
    padding-top: 7.5vw !important;
  }
  .sppr90 {
    padding-right: 7.5vw !important;
  }
  .sppl90 {
    padding-left: 7.5vw !important;
  }
  .sppb90 {
    padding-bottom: 7.5vw !important;
  }
  .spmt95 {
    margin-top: 7.9166666667vw !important;
  }
  .spmr95 {
    margin-right: 7.9166666667vw !important;
  }
  .spml95 {
    margin-left: 7.9166666667vw !important;
  }
  .spmb95 {
    margin-bottom: 7.9166666667vw !important;
  }
  .sppt95 {
    padding-top: 7.9166666667vw !important;
  }
  .sppr95 {
    padding-right: 7.9166666667vw !important;
  }
  .sppl95 {
    padding-left: 7.9166666667vw !important;
  }
  .sppb95 {
    padding-bottom: 7.9166666667vw !important;
  }
  .spmt100 {
    margin-top: 8.3333333333vw !important;
  }
  .spmr100 {
    margin-right: 8.3333333333vw !important;
  }
  .spml100 {
    margin-left: 8.3333333333vw !important;
  }
  .spmb100 {
    margin-bottom: 8.3333333333vw !important;
  }
  .sppt100 {
    padding-top: 8.3333333333vw !important;
  }
  .sppr100 {
    padding-right: 8.3333333333vw !important;
  }
  .sppl100 {
    padding-left: 8.3333333333vw !important;
  }
  .sppb100 {
    padding-bottom: 8.3333333333vw !important;
  }
}

/**
---
name: base button
category: atoms/button
---

* Use `a` or `button` tag.

```html
<a class="btn">Button</a>
```
 */
button {
  display: block;
  border: 0;
  width: 100%;
}

.btn {
  border: 0.0833333333vw solid #000;
  color: #000;
  display: inline-block;
  font-size: 1.0833333333vw;
  letter-spacing: 0.21;
  line-height: 1;
  margin: 0;
  padding: 0.625vw 2.0833333333vw;
  text-align: center;
  text-decoration: none;
}

.btn:hover {
  color: #fff;
  background-color: #000;
}

@media screen and (max-width: 960px) {
  .btn {
    font-size: 1.3541666667vw;
    padding: 0.78125vw 2.6041666667vw;
  }
}

@media screen and (max-width: 767px) {
  .btn {
    font-size: 2.6666666667vw;
    padding: 2vw 6.6666666667vw;
  }
}

.btn-primary {
  padding: 1vw 0.8333333333vw;
  background-color: #fff;
  color: #fff;
  font-size: 1.5vw;
}

@media screen and (max-width: 960px) {
  .btn-primary {
    font-size: 1.875vw;
    padding: 1.25vw 1.0416666667vw;
  }
}

@media screen and (max-width: 767px) {
  .btn-primary {
    font-size: 3.2vw;
    padding: 3.2vw 2.6666666667vw;
  }
}

.btn-large {
  font-size: 1.5833333333vw;
  padding: 0.7vw 2.0833333333vw 1.0833333333vw;
}

@media screen and (max-width: 960px) {
  .btn-large {
    font-size: 1.9791666667vw;
    padding: 1.25vw 2.6041666667vw;
  }
}

@media screen and (max-width: 767px) {
  .btn-large {
    font-size: 5.8666666667vw;
    padding: 2.6666666667vw 13.3333333333vw;
  }
}

/**
---
name: switch
category: atoms/button
---

* Use `a` or `button` tag.

```html
<a class="switch switch-off">Off</a><a class="switch switch-on">On</a>
```
 */
.switch {
  display: inline-block;
  text-decoration: none;
  font-size: 1.25vw;
  text-align: center;
}

@media screen and (max-width: 960px) {
  .switch {
    font-size: 1.5625vw;
  }
}

@media screen and (max-width: 767px) {
  .switch {
    font-size: 4.4vw;
    padding: 2.6666666667vw 5.3333333333vw;
  }
}

.switch-off {
  color: #999;
}

@media screen and (max-width: 767px) {
  .switch-off {
    background-color: #000;
  }
}

.switch-on {
  color: #000;
}

@media screen and (max-width: 767px) {
  .switch-on {
    background-color: #fff;
  }
}

.form-text, .form-textarea, .form-select {
  width: 100%;
  border: 1px solid #000;
  font-size: 1.3333333333vw;
  padding: 0.4166666667vw;
}

@media screen and (max-width: 960px) {
  .form-text, .form-textarea, .form-select {
    font-size: 1.6666666667vw;
    padding: 0.5208333333vw;
  }
}

@media screen and (max-width: 767px) {
  .form-text, .form-textarea, .form-select {
    font-size: 2.9333333333vw;
    padding: 1.3333333333vw;
  }
}

.form-textarea {
  height: 10vw;
}

@media screen and (max-width: 960px) {
  .form-textarea {
    height: 12.5vw;
  }
}

@media screen and (max-width: 767px) {
  .form-textarea {
    height: 16vw;
  }
}

.gray {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

.gray:hover {
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  filter: grayscale(0%);
}

.image-overlay {
  position: relative;
}

.image-overlay:hover .gray {
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  filter: grayscale(0%);
}

.image-overlay::after {
  content: '';
  display: block;
  width: 113px;
  height: 220px;
  position: absolute;
  top: 0;
  right: 0;
}

.image-current::after {
  background-image: linear-gradient(96deg, rgba(216, 216, 216, 0) 14%, rgba(255, 218, 230, 0.79) 89%);
}

.image-past::after {
  background-image: linear-gradient(96deg, rgba(216, 216, 216, 0) 14%, rgba(238, 255, 218, 0.79) 89%);
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  margin: -0.1666666667vw;
  padding: 1.6666666667vw 0;
}

@media screen and (max-width: 960px) {
  .gallery {
    margin: 2.0833333333vw -0.2083333333vw;
    padding: 2.0833333333vw 0;
  }
}

@media screen and (max-width: 767px) {
  .gallery {
    margin: 5.3333333333vw -0.5333333333vw;
    padding: 5.3333333333vw 0;
  }
}

.gallery-item {
  padding: 0.1666666667vw;
  width: 14.6666666667vw;
  height: 14.6666666667vw;
}

@media screen and (max-width: 960px) {
  .gallery-item {
    padding: 0.2083333333vw;
    width: 16.6666666667vw;
    height: 16.6666666667vw;
  }
}

@media screen and (max-width: 767px) {
  .gallery-item {
    padding: 0.5333333333vw;
    width: 44.9333333333vw;
    height: 44.9333333333vw;
  }
}

.gallery-item-image {
  background-position: center;
  background-size: cover;
  height: 100%;
  display: block;
}

.image {
  position: relative;
}

.image-caption {
  position: absolute;
  left: 0.25vw;
  bottom: 0.25vw;
  color: #000;
  text-shadow: 0px 0px 2px #fff;
  font-size: 0.5833333333vw;
}

@media screen and (max-width: 960px) {
  .image-caption {
    right: 0.3125vw;
    bottom: 0.3125vw;
    font-size: 0.7291666667vw;
  }
}

@media screen and (max-width: 767px) {
  .image-caption {
    right: 0.5333333333vw;
    bottom: 0.5333333333vw;
    font-size: 1.8666666667vw;
  }
}

/**
---
name: label
category: atoms/label
---

* Use `span` tag.

```html
<span class="label">Label</a>
<span class="label label-inverse">Label</a>
```
 */
.label {
  display: inline-block;
  padding: 0.3333333333vw;
  font-size: 1.5vw;
  font-weight: normal;
}

@media screen and (max-width: 960px) {
  .label {
    padding: 0.4166666667vw;
    font-size: 1.875vw;
  }
}

@media screen and (max-width: 767px) {
  .label {
    padding: 1.0666666667vw;
    font-size: 3.0666666667vw;
  }
}

.label-inverse {
  border: 0.0833333333vw solid #000;
  background-color: #000;
  color: #fff;
}

@media screen and (max-width: 960px) {
  .label-inverse {
    border: 0.1041666667vw solid #000;
  }
}

@media screen and (max-width: 767px) {
  .label-inverse {
    border: 0.1333333333vw solid #000;
  }
}

.label-large {
  padding: 0.3333333333vw;
  font-size: 1.6666666667vw;
}

@media screen and (max-width: 960px) {
  .label-large {
    padding: 0.4166666667vw;
    font-size: 2.0833333333vw;
  }
}

@media screen and (max-width: 767px) {
  .label-large {
    padding: 1.0666666667vw;
    font-size: 3.4666666667vw;
  }
}

.list-point {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-point li {
  padding-left: 0.8333333333vw;
  margin-bottom: 0.8333333333vw;
  line-height: 1.3;
  letter-spacing: 0.02em;
  position: relative;
}

@media screen and (max-width: 960px) {
  .list-point li {
    padding-left: 1.0416666667vw;
    margin-bottom: 1.0416666667vw;
  }
}

@media screen and (max-width: 767px) {
  .list-point li {
    padding-left: 1.3333333333vw;
    margin-bottom: 1.3333333333vw;
  }
}

.list-point li::before {
  content: '';
  width: 0.3333333333vw;
  height: 0.3333333333vw;
  border-radius: 0.1666666667vw;
  top: 0.5vw;
  display: inline-block;
  background-color: #000;
  position: absolute;
  left: 0;
}

@media screen and (max-width: 960px) {
  .list-point li::before {
    width: 0.4166666667vw;
    height: 0.4166666667vw;
    border-radius: 0.2083333333vw;
    top: 0.625vw;
  }
}

@media screen and (max-width: 767px) {
  .list-point li::before {
    width: 0.8vw;
    height: 0.8vw;
    border-radius: 0.4vw;
    top: 1.0666666667vw;
  }
}

.list-point li:last-child {
  margin-bottom: 0;
}

.list-none {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-none li {
  padding-left: 0;
  margin-bottom: 0.8333333333vw;
  line-height: 1.3;
  letter-spacing: 0.02em;
  position: relative;
}

@media screen and (max-width: 960px) {
  .list-none li {
    margin-bottom: 1.0416666667vw;
  }
}

@media screen and (max-width: 767px) {
  .list-none li {
    margin-bottom: 1.3333333333vw;
  }
}

.list-none li:last-child {
  margin-bottom: 0;
}

ol {
  margin: 0;
  padding: 0;
  padding-left: 1.25vw;
}

@media screen and (max-width: 960px) {
  ol {
    padding-left: 1.5625vw;
  }
}

@media screen and (max-width: 767px) {
  ol {
    padding-left: 2.6666666667vw;
  }
}

ol li {
  margin-bottom: 0.8333333333vw;
  line-height: 1.3;
  letter-spacing: 0.02em;
  position: relative;
}

@media screen and (max-width: 960px) {
  ol li {
    margin-bottom: 1.0416666667vw;
  }
}

@media screen and (max-width: 767px) {
  ol li {
    margin-bottom: 1.3333333333vw;
  }
}

ol li:last-child {
  margin-bottom: 0;
}

/*
---
name: Contents List
category: modules/list
---

```html
<div class="list-residents-item">
    <a href="/residents/detail.html">
        <div class="image-overlay image-current">
            <img src="/images/residents/resident-img1.png" alt="" class="gray">
        </div>
        <div class="list-residents-item-name">
            <span class="label label-inverse">Joke Raes</span>
        </div>
        <p class="list-residents-item-description">September - November 2017<br>Visual artist / British</p>
    </a>
</div>
```
*/
.list-contents {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.8333333333vw;
}

@media screen and (max-width: 960px) {
  .list-contents {
    display: block;
    margin: 2.0833333333vw 0;
  }
}

.list-contents-item {
  width: 33.3333%;
  padding: 0 0.8333333333vw;
  margin-bottom: 3.3333333333vw;
}

@media screen and (max-width: 960px) {
  .list-contents-item {
    width: 100%;
    margin: 2.0833333333vw 0;
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  .list-contents-item {
    margin: 2.6666666667vw 0;
  }
}

.list-contents-item-link {
  display: block;
  text-decoration: none;
}

@media screen and (max-width: 960px) {
  .list-contents-item-link {
    display: flex;
  }
}

.list-contents-item-image {
  height: 15vw;
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 960px) {
  .list-contents-item-image {
    width: 50%;
    height: 28.2666666667vw;
  }
}

@media screen and (max-width: 767px) {
  .list-contents-item-image {
    width: 50%;
    height: 34.1333333333vw;
  }
}

@media screen and (max-width: 960px) {
  .list-contents-item-info {
    padding-left: 1.5625vw;
    width: 50%;
  }
}

.list-contents-item-hr {
  border: 0;
  border-top: 0.0833333333vw solid #000;
  width: 2.5vw;
  margin: 1.6666666667vw 0;
}

@media screen and (max-width: 767px) {
  .list-contents-item-hr {
    margin: 2.6666666667vw 0;
  }
}

.list-contents-item-date {
  font-size: 1vw;
  margin: 1.6666666667vw 0 1vw;
}

@media screen and (max-width: 960px) {
  .list-contents-item-date {
    font-size: 1.25vw;
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .list-contents-item-date {
    font-size: 2.6666666667vw;
    margin-bottom: 2.6666666667vw;
  }
}

.list-contents-item .btn {
  margin: 1.6666666667vw 0 0;
}

@media screen and (max-width: 767px) {
  .list-contents-item .btn {
    display: none;
  }
}

/*
---
name: Residents List
category: modules/list
---

```html
<div class="list-residents-item">
    <a href="/residents/detail.html">
        <div class="image-overlay image-current">
            <img src="/images/residents/resident-img1.png" alt="" class="gray">
        </div>
        <div class="list-residents-item-name">
            <span class="label label-inverse">Joke Raes</span>
        </div>
        <p class="list-residents-item-description">September - November 2017<br>Visual artist / British</p>
    </a>
</div>
```
*/
.list-residents {
  display: flex;
  flex-wrap: wrap;
}

.list-residents.row {
  margin: 0 -0.8333333333vw;
}

@media screen and (max-width: 960px) {
  .list-residents.row {
    margin: 0 -1.0416666667vw;
  }
}

@media screen and (max-width: 767px) {
  .list-residents.row {
    margin: 0 -1.3333333333vw;
  }
}

.list-residents-item {
  margin-top: 2.5vw;
  width: 13.3333333333vw;
}

@media screen and (max-width: 767px) {
  .list-residents-item {
    width: 43.0666666667vw;
    margin-top: 5.3333333333vw;
    margin-bottom: 5.3333333333vw;
  }
}

.list-residents-item a {
  display: block;
  text-decoration: none;
}

.list-residents-item-image {
  width: 100%;
  height: 13.3333333333vw;
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 767px) {
  .list-residents-item-image {
    width: 43.0666666667vw;
    height: 43.0666666667vw;
  }
}

.list-residents-item-name {
  margin-top: 1vw;
  margin-bottom: 0.6666666667vw;
}

@media screen and (max-width: 960px) {
  .list-residents-item-name {
    margin-top: 1.25vw;
    margin-bottom: 0.8333333333vw;
  }
}

@media screen and (max-width: 767px) {
  .list-residents-item-name {
    margin-top: 1.6vw;
    margin-bottom: 1.0666666667vw;
  }
}

.list-residents-item:first-child {
  margin-top: 0;
}

.list-residents-item-description {
  text-decoration: underline;
  line-height: 1.3333333333vw;
  font-size: 1vw;
  margin-top: 0.6666666667vw;
}

@media screen and (max-width: 960px) {
  .list-residents-item-description {
    font-size: 1.25vw;
    line-height: 1.6666666667vw;
    margin-top: 0.6666666667vw;
  }
}

@media screen and (max-width: 767px) {
  .list-residents-item-description {
    font-size: 1.25vw;
    line-height: 4.2666666667vw;
    margin-top: 0.6666666667vw;
  }
}

/*
---
name: Scrollbar
category: modules/scrollbar
---

* Using SimpleBar https://github.com/Grsmto/simplebar

```html
<section class="side-nav" id="side-nav" data-simplebar data-simplebar-autohide="false" style="height: 200px;">
    <div class="side-nav-inner">
        <h2 class="sub-heading-lv2">Residents</h2>
        <section>
            <h3 class="sub-heading-lv3">2018</h3>
            <div class="list-residents">
                <div class="list-residents-item">
                    <a href="/residents/detail.html">
                        <div class="image-overlay image-current">
                            <img src="/images/residents/resident-img0.png" alt="" class="gray">
                        </div>
                        <div class="list-residents-item-name">
                            <span class="label label-inverse">Joke Raes</span>
                        </div>
                        <p class="list-residents-item-description">September - November 2017<br>Visual artist / British</p>
                    </a>
                </div>
            </div>
        </section>
    </div>
</section>
```
*/
.simplebar-track {
  width: 0.1666666667vw;
  background-color: #e7e7e7;
}

.simplebar-scrollbar {
  background-color: #000;
  border-radius: 0;
  width: 0.1666666667vw;
  right: 0;
}

.simplebar-scrollbar.visible {
  opacity: 1;
}

/**
---
name: heading
category: atoms/typograpy
---

* メインコンテンツ用
* Use `h1-5` or `p` tag.

```html
<h1 class="heading-lv1">Heading Lv1</h1>
<h2 class="heading-lv2">Heading Lv2</h2>
<h3 class="heading-lv3">Heading Lv3</h3>
<h4 class="heading-lv4">Heading Lv4</h4>
<h5 class="heading-lv5">Heading Lv5</h5>
```
 */
.heading-lv1 {
  font-size: 2.5vw;
  font-weight: normal;
  margin: 0 0 1.6666666667vw;
}

@media screen and (max-width: 960px) {
  .heading-lv1 {
    font-size: 3.125vw;
    margin: 0 0 2.0833333333vw;
  }
}

@media screen and (max-width: 767px) {
  .heading-lv1 {
    font-size: 6vw;
    margin: 0 0 2.6666666667vw;
  }
}

.heading-lv2 {
  font-size: 2.0833333333vw;
  font-weight: normal;
  margin: 0 0 1.6666666667vw;
  line-height: 1.2;
}

@media screen and (max-width: 960px) {
  .heading-lv2 {
    font-size: 2.6041666667vw;
    margin: 0 0 2.0833333333vw;
  }
}

@media screen and (max-width: 767px) {
  .heading-lv2 {
    font-size: 4.2666666667vw;
    margin-bottom: 2.6666666667vw;
  }
}

.heading-lv3 {
  font-size: 1.5833333333vw;
  font-weight: normal;
  margin: 0 0 1.6666666667vw;
}

@media screen and (max-width: 960px) {
  .heading-lv3 {
    font-size: 1.9791666667vw;
    margin: 0 0 2.0833333333vw;
  }
}

@media screen and (max-width: 767px) {
  .heading-lv3 {
    font-size: 3.7333333333vw;
    margin: 0 0 2.6666666667vw;
  }
}

.heading-lv4 {
  font-size: 1.3333333333vw;
  font-weight: normal;
  margin: 0;
}

@media screen and (max-width: 960px) {
  .heading-lv4 {
    font-size: 1.6666666667vw;
  }
}

@media screen and (max-width: 767px) {
  .heading-lv4 {
    font-size: 2.9333333333vw;
  }
}

.heading-lv5 {
  font-size: 1vw;
  line-height: 1.6;
  font-weight: normal;
  margin: 0;
}

@media screen and (max-width: 960px) {
  .heading-lv5 {
    font-size: 1.25vw;
  }
}

@media screen and (max-width: 767px) {
  .heading-lv5 {
    font-size: 2.6666666667vw;
  }
}

/**
---
name: sub heading
category: atoms/typograpy
---

* サイドナビ用
* Use `h1-5` or `p` tag.

```html
<h2 class="sub-heading-lv2">Sub Heading Lv2</h2>
<h3 class="sub-heading-lv3">Sub Heading Lv3</h3>
```
 */
.sub-heading-lv2 {
  font-size: 1.75vw;
  font-weight: normal;
  margin: 0 0 4.1666666667vw;
}

@media screen and (max-width: 960px) {
  .sub-heading-lv2 {
    font-size: 2.1875vw;
    margin: 0 0 5.2083333333vw;
  }
}

@media screen and (max-width: 767px) {
  .sub-heading-lv2 {
    font-size: 4.2666666667vw;
    margin: 0 0 5.3333333333vw;
  }
}

.sub-heading-lv3 {
  display: inline-block;
  font-size: 1.5vw;
  font-weight: normal;
  margin: 0 0 2.5vw -0.8333333333vw;
  padding: 0 0 0.8333333333vw 0.8333333333vw;
  border-bottom: 0.0833333333vw solid #000;
}

@media screen and (max-width: 960px) {
  .sub-heading-lv3 {
    font-size: 1.875vw;
    margin: 0 0 3.125vw -1.0416666667vw;
    padding: 0 0 1.0416666667vw 1.0416666667vw;
    border-bottom: 0.1041666667vw solid #000;
  }
}

/**
 * サイト全体
 */
body {
  color: #000;
  font-family: 'Lato', 'Hiragino Sans', 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', Meiryo, メイリオ, arial, helvetica, sans-serif;
  overflow-x: hidden;
  font-size: 1.1666666667vw;
  -webkit-font-smoothing: antialiased;
}

@media screen and (max-width: 960px) {
  body {
    font-size: 1.4583333333vw;
  }
}

@media screen and (max-width: 767px) {
  body {
    font-size: 2.6666666667vw;
  }
}

hr {
  width: 30%;
  margin-left: 0;
  border: 0;
  border-top: 1px solid #000;
}

.main {
  width: 100%;
  margin: 4.1666666667vw auto 2.5vw;
  padding: 0 3.3333333333vw;
}

@media screen and (max-width: 960px) {
  .main {
    margin: 5.2083333333vw auto 3.125vw;
    padding: 0 4.1666666667vw;
  }
}

@media screen and (max-width: 767px) {
  .main {
    padding: 21.3333333333vw 5.3333333333vw 0;
    margin: 0 auto;
  }
}

.section {
  margin-top: 4.1666666667vw;
  margin-bottom: 4.1666666667vw;
}

@media screen and (max-width: 960px) {
  .section {
    margin-top: 5.2083333333vw;
    margin-bottom: 5.2083333333vw;
  }
}

@media screen and (max-width: 767px) {
  .section {
    margin-top: 6.6666666667vw;
    margin-bottom: 6.6666666667vw;
  }
}

.contents {
  padding-left: 5vw;
  padding-right: 5vw;
}

@media screen and (max-width: 960px) {
  .contents {
    padding-left: 0;
    padding-right: 0;
  }
}

img {
  width: 100%;
  height: auto;
}

.frame-wrapper {
  display: block;
  position: relative;
  margin: 0 auto;
}

@media screen and (max-width: 960px) {
  .frame-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .frame-wrapper {
    position: static;
  }
}

.frame-wrapper .frame-element-left {
  width: 75vw;
}

@media screen and (max-width: 960px) {
  .frame-wrapper .frame-element-left {
    width: calc(100% - get_vw(300, $viewport-md));
  }
}

@media screen and (max-width: 767px) {
  .frame-wrapper .frame-element-left {
    width: 100%;
  }
}

.frame-wrapper .frame-element-right {
  position: fixed;
  top: 0;
  left: auto;
  margin-left: 75vw;
  border-left: 1px solid #e7e7e7;
  padding: 4.1666666667vw 1.6666666667vw;
  height: 100vh;
}

@media screen and (max-width: 960px) {
  .frame-wrapper .frame-element-right {
    right: 0;
    border-left: 0.0833333333vw solid #e7e7e7;
    padding: 5.2083333333vw 2.0833333333vw;
  }
}

@media screen and (max-width: 767px) {
  .frame-wrapper .frame-element-right {
    display: none;
  }
}

.side-nav {
  height: 100%;
}

.side-nav-inner {
  padding: 0 1.6666666667vw;
  width: 20vw;
}

.side-nav-inner-hr {
  border: 0;
  border-top: 1px solid #d8d8d8;
  margin: 5vw 0;
  width: 100%;
}

.wp-pagenavi {
  text-align: center;
}

.wp-pagenavi .page {
  margin: 0 0.8333333333vw;
}

@media screen and (max-width: 960px) {
  .wp-pagenavi .page {
    margin: 0 1.0416666667vw;
  }
}

@media screen and (max-width: 767px) {
  .wp-pagenavi .page {
    margin: 0 1.3333333333vw;
  }
}

/**
 * テキスト
 */
a {
  color: #000;
  outline: none;
}

p {
  font-size: 1.1666666667vw;
  line-height: 1.6;
  letter-spacing: 0.02em;
  margin: 1.6666666667vw 0;
}

@media screen and (max-width: 960px) {
  p {
    font-size: 1.4583333333vw;
    margin: 2.0833333333vw 0;
  }
}

@media screen and (max-width: 767px) {
  p {
    font-size: 2.6666666667vw;
    margin: 5.3333333333vw 0;
  }
}

.ja .contents p {
  line-height: 2;
}

.ja p.heading-lv5 {
  line-height: 1.6;
}

/**
 *
 */
.mfp-title {
  font-size: 0.5833333333vw;
  color: #000;
  text-shadow: 0px 0px 2px #fff;
  margin-top: -1.5vw;
  margin-left: 0.25vw;
}

@media screen and (max-width: 960px) {
  .mfp-title {
    font-size: 0.7291666667vw;
    margin-top: -1.875vw;
    margin-left: 0.3125vw;
  }
}

@media screen and (max-width: 767px) {
  .mfp-title {
    font-size: 1.8666666667vw;
    margin-top: -4.8vw;
    margin-left: 0.8vw;
  }
}

/* --------------------------
header
-------------------------- */
.header {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #fff;
  }
}

.header-inner {
  width: 100%;
  margin: 4.1666666667vw auto 2.5vw;
  padding: 0 3.3333333333vw;
  display: flex;
  justify-content: space-between;
  /* --------------------------
  ロゴ
  -------------------------- */
  /* --------------------------
  ハンバーガーメニューアイコン
  -------------------------- */
}

@media screen and (max-width: 960px) {
  .header-inner {
    margin: 5.2083333333vw auto 3.125vw;
    padding: 0 4.1666666667vw;
  }
}

@media screen and (max-width: 767px) {
  .header-inner {
    margin: 4vw auto;
    padding: 0 5.3333333333vw;
  }
}

.header-inner .header-logo .header-logo-img {
  width: 10vw;
  height: auto;
}

@media screen and (max-width: 960px) {
  .header-inner .header-logo .header-logo-img {
    width: 12.5vw;
  }
}

@media screen and (max-width: 767px) {
  .header-inner .header-logo .header-logo-img {
    height: 13.3333333333vw;
    width: auto;
  }
}

.header-inner .header-nav {
  display: flex;
}

@media screen and (max-width: 767px) {
  .header-inner .header-nav {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000;
    color: #fff;
    z-index: 100;
    padding-top: 13.3333333333vw;
  }
}

.header-inner .header-nav-others {
  margin-left: 3vw;
}

@media screen and (max-width: 960px) {
  .header-inner .header-nav-others {
    margin-left: 3vw;
  }
}

@media screen and (max-width: 767px) {
  .header-inner .header-nav-others {
    margin-left: 0;
    text-align: center;
  }
}

.header-inner .header-nav-others-list {
  margin: 0 0 1.25vw;
  padding: 0;
  list-style: none;
  display: flex;
}

@media screen and (max-width: 960px) {
  .header-inner .header-nav-others-list {
    margin: 0 0 1.5625vw;
  }
}

.header-inner .header-nav-others-list li + li {
  margin-left: 1.25vw;
}

@media screen and (max-width: 960px) {
  .header-inner .header-nav-others-list li + li {
    margin-left: 1.5625vw;
  }
}

.header-inner .header-nav-others-list li a {
  text-decoration: none;
}

.header-inner .header-menu {
  display: none;
}

@media screen and (max-width: 767px) {
  .header-inner .header-menu {
    position: absolute;
    right: 5.3333333333vw;
    top: 8vw;
    display: block;
    z-index: 1000;
  }
}

.header-inner .header-menu-trigger,
.header-inner .header-menu-trigger span {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
}

.header-inner .header-menu-trigger {
  position: relative;
  width: 5.8666666667vw;
  height: 4.2666666667vw;
}

.header-inner .header-menu-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 0.5333333333vw;
  background-color: #000;
}

.header-inner .header-menu-trigger span:nth-of-type(1) {
  top: 0;
}

.header-inner .header-menu-trigger span:nth-of-type(2) {
  top: 1.8666666667vw;
}

.header-inner .header-menu-trigger span:nth-of-type(3) {
  bottom: 0;
}

.header-inner .header-menu-trigger.active span:nth-of-type(1) {
  -webkit-transform: translateY(1.8666666667vw) rotate(-45deg);
  transform: translateY(1.8666666667vw) rotate(-45deg);
  background-color: #fff;
}

.header-inner .header-menu-trigger.active span:nth-of-type(2) {
  opacity: 0;
  background-color: #fff;
}

.header-inner .header-menu-trigger.active span:nth-of-type(3) {
  -webkit-transform: translateY(-1.8666666667vw) rotate(45deg);
  transform: translateY(-1.8666666667vw) rotate(45deg);
  background-color: #fff;
}

/* --------------------------
Gナビ
-------------------------- */
.nav ul {
  border-left: 0.1666666667vw solid #000;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 960px) {
  .nav ul {
    border-left: 0.1666666667vw solid #000;
  }
}

@media screen and (max-width: 767px) {
  .nav ul {
    border-left: 0;
  }
}

.nav ul li {
  padding-left: 1.4166666667vw;
  line-height: 1;
  letter-spacing: 0.21;
}

@media screen and (max-width: 960px) {
  .nav ul li {
    padding-left: 1.7708333333vw;
  }
}

@media screen and (max-width: 767px) {
  .nav ul li {
    padding-left: 0;
  }
}

.nav ul li + li {
  margin-top: 1.5vw;
}

@media screen and (max-width: 960px) {
  .nav ul li + li {
    margin-top: 1.875vw;
  }
}

.nav ul li a {
  font-size: 1.25vw;
  text-decoration: none;
}

@media screen and (max-width: 960px) {
  .nav ul li a {
    font-size: 1.5625vw;
  }
}

@media screen and (max-width: 767px) {
  .nav ul li a {
    font-size: 6.4vw;
    line-height: 9.0666666667vw;
    color: #fff;
    text-align: center;
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .apply-btn {
    color: #fff;
    border-color: #fff;
  }
}

/* --------------------------
footer
-------------------------- */
.footer {
  margin-top: 6.6666666667vw;
  border-top: 0.0833333333vw solid #e7e7e7;
}

@media screen and (max-width: 960px) {
  .footer {
    margin-top: 8.3333333333vw;
  }
}

.footer-inner {
  width: 100%;
  margin: 2.5vw auto;
  padding: 0 3.3333333333vw;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media screen and (max-width: 960px) {
  .footer-inner {
    flex-direction: column-reverse;
    margin: 3.125vw auto;
    padding: 0 4.1666666667vw;
  }
}

@media screen and (max-width: 767px) {
  .footer-inner {
    margin: 4vw auto;
    padding: 0 5.3333333333vw;
    display: block;
  }
}

.footer-info {
  width: 50%;
}

@media screen and (max-width: 960px) {
  .footer-info {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .footer-info {
    width: 100%;
    font-size: 2.6666666667vw;
  }
}

.footer-info-participants {
  font-size: 1vw;
  line-height: 1.5;
}

@media screen and (max-width: 960px) {
  .footer-info-participants {
    font-size: 1.25vw;
  }
}

@media screen and (max-width: 767px) {
  .footer-info-participants {
    font-size: 2.6666666667vw;
  }
}

.footer-links-area {
  width: 50%;
}

@media screen and (max-width: 960px) {
  .footer-links-area {
    width: 100%;
    display: flex;
  }
}

.footer-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 1.6666666667vw;
}

.footer-links:first-child {
  margin-top: 0;
}

@media screen and (max-width: 960px) {
  .footer-links:first-child {
    margin-top: 3.125vw;
    margin-right: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  .footer-links:first-child {
    margin-top: 4vw;
    margin-right: 4vw;
  }
}

@media screen and (max-width: 960px) {
  .footer-links {
    width: 100%;
    margin-top: 3.125vw;
    margin-bottom: 3.125vw;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 767px) {
  .footer-links {
    width: 100%;
    margin-bottom: 5.3333333333vw;
    justify-content: flex-start;
  }
}

.footer-links li + li {
  margin-left: 2.5vw;
}

@media screen and (max-width: 960px) {
  .footer-links li + li {
    margin-left: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  .footer-links li + li {
    margin-left: 4vw;
  }
}

.footer-links-cifnl {
  width: 6.5vw;
}

@media screen and (max-width: 960px) {
  .footer-links-cifnl {
    width: 8.125vw;
  }
}

@media screen and (max-width: 767px) {
  .footer-links-cifnl {
    width: 16vw;
  }
}

.footer-links-mondriaan {
  width: 2.9166666667vw;
}

@media screen and (max-width: 960px) {
  .footer-links-mondriaan {
    width: 3.6458333333vw;
  }
}

@media screen and (max-width: 767px) {
  .footer-links-mondriaan {
    width: 7.0666666667vw;
  }
}

.footer-links-netherlands {
  width: 4.8333333333vw;
}

@media screen and (max-width: 960px) {
  .footer-links-netherlands {
    width: 6.0416666667vw;
  }
}

@media screen and (max-width: 767px) {
  .footer-links-netherlands {
    width: 12vw;
  }
}

.footer-links-res-artis {
  width: 6.5vw;
}

@media screen and (max-width: 960px) {
  .footer-links-res-artis {
    width: 8.125vw;
  }
}

@media screen and (max-width: 767px) {
  .footer-links-res-artis {
    width: 16vw;
  }
}

.footer-links-saga-pref {
  width: 3.8333333333vw;
}

@media screen and (max-width: 960px) {
  .footer-links-saga-pref {
    width: 4.7916666667vw;
  }
}

@media screen and (max-width: 767px) {
  .footer-links-saga-pref {
    width: 9.4666666667vw;
  }
}

.copyright {
  font-size: 0.5vw;
  margin: 0;
  text-align: center;
}

@media screen and (max-width: 960px) {
  .copyright {
    font-size: 0.625vw;
  }
}

@media screen and (max-width: 767px) {
  .copyright {
    font-size: 2.6666666667vw;
  }
}

.keyvisual {
  position: relative;
}

.keyvisual a {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .keyvisual {
    margin: 0 -5.3333333333vw;
  }
}

.keyvisual .keyvisual-wrapper {
  margin-bottom: 0;
}

.keyvisual-item {
  position: relative;
}

.keyvisual-item-image {
  height: 28.3333333333vw;
  background-position: center;
  background-size: cover;
  position: relative;
}

@media screen and (max-width: 960px) {
  .keyvisual-item-image {
    height: 45.3333333333vw;
  }
}

@media screen and (max-width: 767px) {
  .keyvisual-item-image {
    height: 50.6666666667vw;
  }
}

.keyvisual-item-contents {
  margin: 1.6666666667vw 0 0;
  position: relative;
}

@media screen and (max-width: 960px) {
  .keyvisual-item-contents {
    margin: 2.0833333333vw 0 0;
  }
}

@media screen and (max-width: 767px) {
  .keyvisual-item-contents {
    margin: 2.6666666667vw 0 0 5.3333333333vw;
  }
}

.keyvisual-date {
  font-size: 1.5vw;
  display: block;
  margin: 0.8333333333vw 0 0;
}

@media screen and (max-width: 960px) {
  .keyvisual-date {
    font-size: 1.875vw;
    margin: 1.0416666667vw 0 0;
  }
}

@media screen and (max-width: 767px) {
  .keyvisual-date {
    font-size: 3.0666666667vw;
    margin: 1.3333333333vw 0 0;
  }
}

.keyvisual-heading {
  font-size: 2.3333333333vw;
  margin: 0;
  font-weight: normal;
  display: block;
  padding-bottom: 0.25vw;
}

@media screen and (max-width: 960px) {
  .keyvisual-heading {
    font-size: 2.9166666667vw;
    padding-bottom: 0.3125vw;
  }
}

@media screen and (max-width: 767px) {
  .keyvisual-heading {
    font-size: 5.0666666667vw;
    padding-bottom: 0.625vw;
  }
}

.keyvisual .slick-dots {
  position: absolute;
  top: 30.4166666667vw;
  text-align: right;
  margin-right: 0.8333333333vw;
}

@media screen and (max-width: 960px) {
  .keyvisual .slick-dots {
    top: auto;
    bottom: -2.0833333333vw;
    text-align: center;
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .keyvisual .slick-dots {
    top: auto;
    bottom: -4vw;
  }
}

.keyvisual .slick-dots li {
  width: 0.8333333333vw;
  height: 0.8333333333vw;
}

@media screen and (max-width: 960px) {
  .keyvisual .slick-dots li {
    width: 0.8333333333vw;
    height: 0.8333333333vw;
  }
}

@media screen and (max-width: 767px) {
  .keyvisual .slick-dots li {
    width: 2.1333333333vw;
    height: 2.1333333333vw;
  }
}

.keyvisual .slick-dots li:last-child {
  margin-right: 0;
}

.keyvisual .slick-dots li button {
  width: 0.8333333333vw;
  height: 0.8333333333vw;
  padding: 0;
}

@media screen and (max-width: 960px) {
  .keyvisual .slick-dots li button {
    width: 0.8333333333vw;
    height: 0.8333333333vw;
  }
}

@media screen and (max-width: 767px) {
  .keyvisual .slick-dots li button {
    width: 2.1333333333vw;
    height: 2.1333333333vw;
  }
}

.keyvisual .slick-dots li button::before {
  width: 0.8333333333vw;
  height: 0.8333333333vw;
  font-size: 0.5vw;
  content: '●';
  line-height: 1;
}

@media screen and (max-width: 960px) {
  .keyvisual .slick-dots li button::before {
    width: 0.8333333333vw;
    height: 0.8333333333vw;
    font-size: 0.625vw;
  }
}

@media screen and (max-width: 767px) {
  .keyvisual .slick-dots li button::before {
    width: 2.1333333333vw;
    height: 2.1333333333vw;
    font-size: 1.6vw;
  }
}

.map {
  width: 100%;
  height: 400px;
}

.residents-gallery {
  display: flex;
  flex-wrap: wrap;
  margin: -0.1666666667vw;
  padding: 1.6666666667vw 0;
}

@media screen and (max-width: 960px) {
  .residents-gallery {
    margin: 2.0833333333vw -0.2083333333vw;
    padding: 2.0833333333vw 0;
  }
}

@media screen and (max-width: 767px) {
  .residents-gallery {
    margin: 5.3333333333vw -0.5333333333vw;
    padding: 5.3333333333vw 0;
  }
}

.residents-gallery-item {
  padding: 0.1666666667vw;
  width: 11.6666666667vw;
  height: 11.6666666667vw;
}

@media screen and (max-width: 960px) {
  .residents-gallery-item {
    padding: 0.2083333333vw;
    width: 13.3333333333vw;
    height: 13.3333333333vw;
  }
}

@media screen and (max-width: 767px) {
  .residents-gallery-item {
    padding: 0.5333333333vw;
    width: 30.1333333333vw;
    height: 30.1333333333vw;
  }
}

.residents-gallery-item-image {
  background-position: center;
  background-size: cover;
  height: 100%;
}
