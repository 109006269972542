// Generate default CSS rules
/**
 * サイト全体
 */
body {
  color: $color-text-default;
  font-family: 'Lato', 'Hiragino Sans', 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', Meiryo, メイリオ, arial, helvetica, sans-serif;
  overflow-x: hidden;
  font-size: get_vw(14);
  -webkit-font-smoothing: antialiased;
  @include mq(md) {
    font-size: get_vw(14, $viewport-md);
  }
  @include mq(sm) {
    font-size: get_vw(20, $viewport-sm);
  }
}

hr {
  width: 30%;
  margin-left: 0;
  border: 0;
  border-top: 1px solid $color-border-default;
}
.main {
  width: 100%;
  margin: get_vw(50) auto get_vw(30);
  padding:0 get_vw($padding-contents);
  @include mq(md) {
    margin: get_vw(50, $viewport-md) auto get_vw(30, $viewport-md);
    padding:0 get_vw($padding-contents, $viewport-md);
  }
  @include mq(sm) {
    padding: get_vw(160, $viewport-sm) get_vw(40, $viewport-sm) 0;
    margin: 0 auto;
  }
}
.section {
  margin-top: get_vw(50);
  margin-bottom: get_vw(50);
  @include mq(md) {
    margin-top: get_vw(50, $viewport-md);
    margin-bottom: get_vw(50, $viewport-md);
  }
  @include mq(sm) {
    margin-top: get_vw(50, $viewport-sm);
    margin-bottom: get_vw(50, $viewport-sm);
  }
}
.contents {
  padding-left: get_vw(60);
  padding-right: get_vw(60);
  @include mq(md) {
    padding-left: 0;
    padding-right: 0;
  }
}

img {
  width: 100%;
  height: auto;
}

.frame-wrapper {
  display: block;
  position: relative;
  margin: 0 auto;
  @include mq(md) {
    width: 100%;
  }
  @include mq(sm) {
    position: static;
  }

  .frame-element-left {
    width: get_vw(900);
    @include mq(md) {
      width: calc(100% - get_vw(300, $viewport-md));
    }
    @include mq(sm) {
      width: 100%;
    }
  }
  .frame-element-right {
    position: fixed;
    top: 0;
    left: auto;
    margin-left: get_vw(900);
    border-left: 1px solid #e7e7e7;
    padding: get_vw(50) get_vw(20);
    height: 100vh;
    @include mq(md) {
      right: 0;
      border-left: get_vw(1) solid #e7e7e7;
      padding: get_vw(50, $viewport-md) get_vw(20, $viewport-md);
    }
    @include mq(sm) {
      display: none;
    }
  }
}

.side-nav {
  height: 100%;
}
.side-nav-inner {
  padding: 0 get_vw(20);
  width: get_vw(240);
  &-hr {
    border: 0;
    border-top: 1px solid #d8d8d8;
    margin: get_vw(60) 0;
    width: 100%;
  }
}

.wp-pagenavi {
  text-align: center;
  .page {
    margin: 0 get_vw(10);
    @include mq(md) {
      margin: 0 get_vw(10, $viewport-md);
    }
    @include mq(sm) {
      margin: 0 get_vw(10, $viewport-sm);
    }
  }
}

/**
 * テキスト
 */
a {
  color: $color-text-default;
  outline: none;
}
p {
  font-size: get_vw(14);
  line-height: 1.6;
  letter-spacing: 0.02em;
  margin: get_vw(20) 0;
  @include mq(md) {
    font-size: get_vw(14, $viewport-md);
    margin: get_vw(20, $viewport-md) 0;
  }
  @include mq(sm) {
    font-size: get_vw(20, $viewport-sm);
    margin: get_vw(40, $viewport-sm) 0;
  }
}
.ja .contents p {
  line-height: 2;
}
.ja p.heading-lv5 {
  line-height: 1.6;
}


/**
 *
 */
.mfp-title {
  font-size: get_vw(7);
  color: $color-text-default;
  text-shadow: 0px 0px 2px $color-text-inverse;
  margin-top: get_vw(-18);
  margin-left: get_vw(3);
  @include mq(md) {
    font-size: get_vw(7, $viewport-md);
    margin-top: get_vw(-18, $viewport-md);
    margin-left: get_vw(3, $viewport-md);
  }
  @include mq(sm) {
    font-size: get_vw(14, $viewport-sm);
    margin-top: get_vw(-36, $viewport-sm);
    margin-left: get_vw(6, $viewport-sm);
  }
}
