

/* --------------------------
color
-------------------------- */
$color-background-default: #fff;
$color-background-inverse: #000;

$color-border-default: #000;
$color-border-inverse: #fff;

$color-button-default:  #FFFFFF;
$color-button-inverse:  #000000;

$color-line-default: #000;

$color-link-default: #000;
$color-link-hover: #000;
$color-link-active: #000;
$color-link-visited: #000;
$color-link-inverse: #ffffff;

$color-text-default: #000;
$color-text-off: #999;
$color-text-inverse: #fff;

/* --------------------------
font-size
-------------------------- */
$font-size-lv1: get_vw(30);
$font-size-lv2: get_vw(25);
$font-size-lv3: get_vw(19);
$font-size-lv4: get_vw(14);
$font-size-lv5: get_vw(12);

$font-size-default: get_vw(12);


/* --------------------------
spacing
-------------------------- */
$margin-section-main: 60;
$margin-section-side: 50;
$margin-title-bottom: 20;

$padding-contents: 40;
$padding-contents-sp: 40;

/* --------------------------
contents width
-------------------------- */
$contents-width: 900;


/* --------------------------
z-index order
-------------------------- */

/* --------------------------
viewport
-------------------------- */
$viewport-lg: 1200;
$viewport-md: 960;
$viewport-sm: 750;
