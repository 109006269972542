.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 get_vw(-20);
}

@for $i from 1 through 12
{
  $width: (100% / (12/$i));
  .col-lg-#{$i}
  {
    width: $width;
    padding: get_vw(20);
  }
}
@include mq(md) {
  .row {
    margin: 0 get_vw(-20, $viewport-md);
  }

  @for $i from 1 through 12
  {
    $width: (100% / (12/$i));
    .col-lg-#{$i}
    {
      width: $width;
      padding: get_vw(20, $viewport-md);
    }
    .col-md-#{$i}
    {
      width: $width;
      padding: get_vw(20, $viewport-md);
    }
  }
}

@include mq(sm) {
  .row {
    margin: 0 get_vw(-20, $viewport-sm);
  }

  @for $i from 1 through 12
  {
    $width: (100% / (12/$i));
    .col-lg-#{$i}
    {
      width: $width;
      padding: get_vw(20, $viewport-sm);
    }
    .col-md-#{$i}
    {
      width: $width;
      padding: get_vw(20, $viewport-sm);
    }
    .col-sm-#{$i}
    {
      width: $width;
      padding: get_vw(20, $viewport-sm);
    }
  }
}
