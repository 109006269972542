/**
---
name: heading
category: atoms/typograpy
---

* メインコンテンツ用
* Use `h1-5` or `p` tag.

```html
<h1 class="heading-lv1">Heading Lv1</h1>
<h2 class="heading-lv2">Heading Lv2</h2>
<h3 class="heading-lv3">Heading Lv3</h3>
<h4 class="heading-lv4">Heading Lv4</h4>
<h5 class="heading-lv5">Heading Lv5</h5>
```
 */

.heading-lv1 {
  font-size: get_vw(30);
  font-weight: normal;
  margin: 0 0 get_vw($margin-title-bottom);
  @include mq(md) {
    font-size: get_vw(30, $viewport-md);
    margin: 0 0 get_vw($margin-title-bottom, $viewport-md);
  }
  @include mq(sm) {
    font-size: get_vw(45, $viewport-sm);
    margin: 0 0 get_vw($margin-title-bottom, $viewport-sm);
  }
}
.heading-lv2 {
  font-size: get_vw(25);
  font-weight: normal;
  margin: 0 0 get_vw($margin-title-bottom);
  line-height: 1.2;
  @include mq(md) {
    font-size: get_vw(25, $viewport-md);
    margin: 0 0 get_vw($margin-title-bottom, $viewport-md);
  }
  @include mq(sm) {
    font-size: get_vw(32, $viewport-sm);
    margin-bottom: get_vw(20, $viewport-sm);
  }
}
.heading-lv3 {
  font-size: get_vw(19);
  font-weight: normal;
  margin: 0 0 get_vw($margin-title-bottom);
  @include mq(md) {
    font-size: get_vw(19, $viewport-md);
    margin: 0 0 get_vw($margin-title-bottom, $viewport-md);
  }
  @include mq(sm) {
    font-size: get_vw(28, $viewport-sm);
    margin: 0 0 get_vw($margin-title-bottom, $viewport-sm);
  }
}
.heading-lv4 {
  font-size: get_vw(16);
  font-weight: normal;
  margin: 0;
  @include mq(md) {
    font-size: get_vw(16, $viewport-md);
  }
  @include mq(sm) {
    font-size: get_vw(22, $viewport-sm);
  }
}
.heading-lv5 {
  font-size: get_vw(12);
  line-height: 1.6;
  font-weight: normal;
  margin: 0;
  @include mq(md) {
    font-size: get_vw(12, $viewport-md);
  }
  @include mq(sm) {
    font-size: get_vw(20, $viewport-sm);
  }
}

/**
---
name: sub heading
category: atoms/typograpy
---

* サイドナビ用
* Use `h1-5` or `p` tag.

```html
<h2 class="sub-heading-lv2">Sub Heading Lv2</h2>
<h3 class="sub-heading-lv3">Sub Heading Lv3</h3>
```
 */


.sub-heading-lv2 {
  font-size: get_vw(21);
  font-weight: normal;
  margin: 0 0 get_vw(50);
  @include mq(md) {
    font-size: get_vw(21, $viewport-md);
    margin: 0 0 get_vw(50, $viewport-md);
  }
  @include mq(sm) {
    font-size: get_vw(32, $viewport-sm);
    margin: 0 0 get_vw(40, $viewport-sm);
  }
}

.sub-heading-lv3 {
  display: inline-block;
  font-size: get_vw(18);
  font-weight: normal;
  margin: 0 0 get_vw(30) get_vw(-10);
  padding: 0 0 get_vw(10) get_vw(10);
  border-bottom: get_vw(1) solid $color-border-default;
  @include mq(md) {
    font-size: get_vw(18, $viewport-md);
    margin: 0 0 get_vw(30, $viewport-md) get_vw(-10, $viewport-md);
    padding: 0 0 get_vw(10, $viewport-md) get_vw(10, $viewport-md);
    border-bottom: get_vw(1, $viewport-md) solid $color-border-default;
  }
}
