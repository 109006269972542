

/**
---
name: base button
category: atoms/button
---

* Use `a` or `button` tag.

```html
<a class="btn">Button</a>
```
 */
button {
  display: block;
  border: 0;
  width: 100%;
}
.btn {
  border: get_vw(1) solid $color-border-default;
  color: $color-text-default;
  display: inline-block;
  font-size: get_vw(13);
  letter-spacing: 0.21;
  line-height: 1;
  margin: 0;
  padding: get_vw(7.5) get_vw(25);
  text-align: center;
  text-decoration: none;
  &:hover {
    color: $color-text-inverse;
    background-color: $color-background-inverse;
  }
  @include mq(md) {
    font-size: get_vw(13, $viewport-md);
    padding: get_vw(7.5, $viewport-md) get_vw(25, $viewport-md);
  }
  @include mq(sm) {
    font-size: get_vw(20, $viewport-sm);
    padding: get_vw(15, $viewport-sm) get_vw(50, $viewport-sm);
  }
}
.btn-primary {
  padding: get_vw(12) get_vw(10);
  background-color: $color-background-default;
  color: $color-text-inverse;
  font-size: get_vw(18);
  @include mq(md) {
    font-size: get_vw(18, $viewport-md);
    padding: get_vw(12, $viewport-md) get_vw(10, $viewport-md);
  }
  @include mq(sm) {
    font-size: get_vw(24, $viewport-sm);
    padding: get_vw(24, $viewport-sm) get_vw(20, $viewport-sm);
  }
}
.btn-large {
  font-size: get_vw(19);
  padding: 0.7vw get_vw(25) get_vw(13);
  @include mq(md) {
    font-size: get_vw(19, $viewport-md);
    padding: get_vw(12, $viewport-md) get_vw(25, $viewport-md);
  }
  @include mq(sm) {
    font-size: get_vw(44, $viewport-sm);
    padding: get_vw(20, $viewport-sm) get_vw(100, $viewport-sm);
  }
}

/**
---
name: switch
category: atoms/button
---

* Use `a` or `button` tag.

```html
<a class="switch switch-off">Off</a><a class="switch switch-on">On</a>
```
 */
.switch {
  display: inline-block;
  text-decoration: none;
  font-size: get_vw(15);
  text-align: center;
  @include mq(md) {
    font-size: get_vw(15, $viewport-md);
  }
   @include mq(sm) {
    font-size: get_vw(33, $viewport-sm);
    padding: get_vw(20, $viewport-sm) get_vw(40, $viewport-sm);
  }
}
.switch-off {
  color: $color-text-off;
  @include mq(sm) {
    background-color: $color-background-inverse;
  }
}
.switch-on {
  color: $color-text-default;
  @include mq(sm) {
    background-color: $color-background-default;
  }
}
