
.form {
  &-text,
  &-textarea,
  &-select {
    width: 100%;
    border: 1px solid $color-border-default;
    font-size: get_vw(16);
    padding: get_vw(5);
    @include mq(md) {
      font-size: get_vw(16, $viewport-md);
      padding: get_vw(5, $viewport-md);
    }
    @include mq(sm) {
      font-size: get_vw(22, $viewport-sm);
      padding: get_vw(10, $viewport-sm);
    }
  }

  &-textarea {
    height: get_vw(120);
    @include mq(md) {
      height: get_vw(120, $viewport-md);
    }
    @include mq(sm) {
      height: get_vw(120, $viewport-sm);
    }
  }
}
