/**
---
name: spacing
category: utilities/spacing
---

### margin paddingを強制的に上書きする際に利用します。

* 5px刻みで0〜100まで
* スマホとPCで異なる場合はクラス名にspを付けたものを追加する

```html
<div class="mt20 mr20 ml20 mb20 spmt20 spmr20 spml20 spmb20"></div>
<div class="pt20 pr20 pl20 pb20 sppt20 sppr20 sppl20 sppb20"></div>
```
 */

/*==================================================================
margin padding styles
===================================================================*/

@for $i from 0 through 20 {
    .mt#{$i * 5} {margin-top: get_vw(#{$i * 5}) !important;}
    .mr#{$i * 5} {margin-right: get_vw(#{$i * 5}) !important;}
    .ml#{$i * 5} {margin-left: get_vw(#{$i * 5}) !important;}
    .mb#{$i * 5} {margin-bottom: get_vw(#{$i * 5}) !important;}
    .pt#{$i * 5} {padding-top: get_vw(#{$i * 5}) !important;}
    .pr#{$i * 5} {padding-right: get_vw(#{$i * 5}) !important;}
    .pl#{$i * 5} {padding-left: get_vw(#{$i * 5}) !important;}
    .pb#{$i * 5} {padding-bottom: get_vw(#{$i * 5}) !important;}
}
@include mq(md) {
    @for $i from 0 through 20 {
      .mt#{$i * 5} {margin-top: get_vw(#{$i * 5}, $viewport-md) !important;}
      .mr#{$i * 5} {margin-right: get_vw(#{$i * 5}, $viewport-md) !important;}
      .ml#{$i * 5} {margin-left: get_vw(#{$i * 5}, $viewport-md) !important;}
      .mb#{$i * 5} {margin-bottom: get_vw(#{$i * 5}, $viewport-md) !important;}
      .pt#{$i * 5} {padding-top: get_vw(#{$i * 5}, $viewport-md) !important;}
      .pr#{$i * 5} {padding-right: get_vw(#{$i * 5}, $viewport-md) !important;}
      .pl#{$i * 5} {padding-left: get_vw(#{$i * 5}, $viewport-md) !important;}
      .pb#{$i * 5} {padding-bottom: get_vw(#{$i * 5}, $viewport-md) !important;}
    }
}
@include mq(sm) {
    @for $i from 0 through 20 {
        .spmt#{$i * 5} {margin-top: get_vw(#{$i * 5}) !important;}
        .spmr#{$i * 5} {margin-right: get_vw(#{$i * 5}) !important;}
        .spml#{$i * 5} {margin-left: get_vw(#{$i * 5}) !important;}
        .spmb#{$i * 5} {margin-bottom: get_vw(#{$i * 5}) !important;}
        .sppt#{$i * 5} {padding-top: get_vw(#{$i * 5}) !important;}
        .sppr#{$i * 5} {padding-right: get_vw(#{$i * 5}) !important;}
        .sppl#{$i * 5} {padding-left: get_vw(#{$i * 5}) !important;}
        .sppb#{$i * 5} {padding-bottom: get_vw(#{$i * 5}) !important;}
    }
}
