
/*
---
name: Scrollbar
category: modules/scrollbar
---

* Using SimpleBar https://github.com/Grsmto/simplebar

```html
<section class="side-nav" id="side-nav" data-simplebar data-simplebar-autohide="false" style="height: 200px;">
    <div class="side-nav-inner">
        <h2 class="sub-heading-lv2">Residents</h2>
        <section>
            <h3 class="sub-heading-lv3">2018</h3>
            <div class="list-residents">
                <div class="list-residents-item">
                    <a href="/residents/detail.html">
                        <div class="image-overlay image-current">
                            <img src="/images/residents/resident-img0.png" alt="" class="gray">
                        </div>
                        <div class="list-residents-item-name">
                            <span class="label label-inverse">Joke Raes</span>
                        </div>
                        <p class="list-residents-item-description">September - November 2017<br>Visual artist / British</p>
                    </a>
                </div>
            </div>
        </section>
    </div>
</section>
```
*/

.simplebar-track {
  width: get_vw(2);
  background-color: #e7e7e7;
}
.simplebar-scrollbar {
  background-color: #000;
  border-radius: 0;
  width: get_vw(2);
  right: 0;

  &.visible {
    opacity: 1;
  }
}
