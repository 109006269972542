/**
---
name: label
category: atoms/label
---

* Use `span` tag.

```html
<span class="label">Label</a>
<span class="label label-inverse">Label</a>
```
 */

.label {
  display: inline-block;
  padding: get_vw(4);
  font-size: get_vw(18);
  font-weight: normal;
  @include mq(md) {
    padding: get_vw(4, $viewport-md);
    font-size: get_vw(18, $viewport-md);
  }
  @include mq(sm) {
    padding: get_vw(8, $viewport-sm);
    font-size: get_vw(23, $viewport-sm);
  }
}
.label-inverse {
  border: get_vw(1) solid $color-background-inverse;
  background-color: $color-background-inverse;
  color: $color-text-inverse;
  @include mq(md) {
    border: get_vw(1, $viewport-md) solid $color-background-inverse;
  }
  @include mq(sm) {
    border: get_vw(1, $viewport-sm) solid $color-background-inverse;
  }
}

.label-large {
  padding: get_vw(4);
  font-size: get_vw(20);
  @include mq(md) {
    padding: get_vw(4, $viewport-md);
    font-size: get_vw(20, $viewport-md);
  }
  @include mq(sm) {
    padding: get_vw(8, $viewport-sm);
    font-size: get_vw(26, $viewport-sm);
  }
}
