
/* --------------------------
footer
-------------------------- */
.footer {
  margin-top: get_vw(80);
  border-top: get_vw(1) solid #e7e7e7;
  @include mq(md) {
    margin-top: get_vw(80, $viewport-md);
  }
  &-inner {
    width: 100%;
    margin: get_vw(30) auto;
    padding:0 get_vw($padding-contents);
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    @include mq(md) {
      flex-direction: column-reverse;
      margin: get_vw(30, $viewport-md) auto;
      padding:0 get_vw($padding-contents, $viewport-md);
    }
    @include mq(sm) {
      margin: get_vw(30, $viewport-sm) auto;
      padding: 0 get_vw(40, $viewport-sm);
      display: block;
    }
  }

  &-info {
    width: 50%;
    @include mq(md) {
      width: 100%;
    }
    @include mq(sm) {
      width: 100%;
      font-size: get_vw(20, $viewport-sm);
    }
    &-participants {
      font-size: get_vw(12);
      line-height: 1.5 ;
      @include mq(md) {
        font-size: get_vw(12, $viewport-md);
      }
      @include mq(sm) {
        font-size: get_vw(20, $viewport-sm);
      }
    }
  }
  &-links-area {
    width: 50%;
    @include mq(md) {
      width: 100%;
      display: flex;
    }
  }
  &-links {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: get_vw(20);
    &:first-child {
      margin-top: 0;
      @include mq(md) {
        margin-top: get_vw(30, $viewport-md);
        margin-right: get_vw(30, $viewport-md);
      }
      @include mq(sm) {
        margin-top: get_vw(30, $viewport-sm);
        margin-right: get_vw(30, $viewport-sm);
      }
    }
    @include mq(md) {
      width: 100%;
      margin-top: get_vw(30, $viewport-md);
      margin-bottom: get_vw(30, $viewport-md);
      justify-content: flex-start;
    }
    @include mq(sm) {
      width: 100%;
      margin-bottom: get_vw(40, $viewport-sm);
      justify-content: flex-start;
    }
    li {
      +li {
        margin-left: get_vw(30);
        @include mq(md) {
          margin-left: get_vw(30, $viewport-md);
        }
        @include mq(sm) {
          margin-left: get_vw(30, $viewport-sm);
        }
      }
    }
    &-cifnl {
      width: get_vw(78);
      @include mq(md) {
        width: get_vw(78, $viewport-md);
      }
      @include mq(sm) {
        width: get_vw(120, $viewport-sm);
      }
    }
    &-mondriaan {
      width: get_vw(35);
      @include mq(md) {
        width: get_vw(35, $viewport-md);
      }
      @include mq(sm) {
        width: get_vw(53, $viewport-sm);
      }
    }
    &-netherlands {
      width: get_vw(58);
      @include mq(md) {
        width: get_vw(58, $viewport-md);
      }
      @include mq(sm) {
        width: get_vw(90, $viewport-sm);
      }
    }
    &-res-artis {
      width: get_vw(78);
      @include mq(md) {
        width: get_vw(78, $viewport-md);
      }
      @include mq(sm) {
        width: get_vw(120, $viewport-sm);
      }
    }
    &-saga-pref {
      width: get_vw(46);
      @include mq(md) {
        width: get_vw(46, $viewport-md);
      }
      @include mq(sm) {
        width: get_vw(71, $viewport-sm);
      }
    }
  }
}
.copyright {
  font-size: get_vw(6);
  margin: 0;
  text-align: center;
  @include mq(md) {
    font-size: get_vw(6, $viewport-md);
  }
  @include mq(sm) {
    font-size: get_vw(20, $viewport-sm);
  }
}
