
.keyvisual {
  position: relative;
  a {
    text-decoration: none;
  }
  @include mq(sm) {
    margin: 0 get_vw(-40, $viewport-sm);
  }
  .keyvisual-wrapper {
    margin-bottom: 0;
  }
  &-item {
    position: relative;
    &-image {
      height: get_vw(340);
      background-position: center;
      background-size: cover;
      position: relative;
      @include mq(md) {
        height: get_vw(340, $viewport-sm);
      }
      @include mq(sm) {
        height: get_vw(380, $viewport-sm);
      }
    }
  }
  &-item-contents {
    margin: get_vw(20) 0 0;
    position: relative;
    @include mq(md) {
      margin: get_vw(20, $viewport-md) 0 0;
    }
    @include mq(sm) {
      margin: get_vw(20, $viewport-sm) 0 0 get_vw(40, $viewport-sm);
    }
  }
  &-date {
    font-size: get_vw(18);
    display: block;
    margin: get_vw(10) 0 0;
    @include mq(md) {
      font-size: get_vw(18, $viewport-md);
      margin: get_vw(10, $viewport-md) 0 0;
    }
    @include mq(sm) {
      font-size: get_vw(23, $viewport-sm);
      margin: get_vw(10, $viewport-sm) 0 0;
    }
  }
  &-heading {
    font-size: get_vw(28);
    margin: 0;
    font-weight: normal;
    display: block;
    padding-bottom: get_vw(3);
    @include mq(md) {
      font-size: get_vw(28, $viewport-md);
      padding-bottom: get_vw(3, $viewport-md);
    }
    @include mq(sm) {
      font-size: get_vw(38, $viewport-sm);
      padding-bottom: get_vw(6, $viewport-md);
    }
  }
  .slick-dots {
    position: absolute;
    top: get_vw(365);
    text-align: right;
    margin-right: get_vw(10);
    @include mq(md) {
      top: auto;
      bottom: get_vw(-20, $viewport-md);
      text-align: center;
      margin-right: 0;
    }
    @include mq(sm) {
      top: auto;
      bottom: get_vw(-30, $viewport-sm);
    }
    li {
      width: get_vw(10);
      height: get_vw(10);
      @include mq(md) {
        width: get_vw(8, $viewport-md);
        height: get_vw(8, $viewport-md);
      }
      @include mq(sm) {
        width: get_vw(16, $viewport-sm);
        height: get_vw(16, $viewport-sm);
      }
      &:last-child {
        margin-right: 0;
      }
      button {
        width: get_vw(10);
        height: get_vw(10);
        padding: 0;
        @include mq(md) {
          width: get_vw(8, $viewport-md);
          height: get_vw(8, $viewport-md);
        }
        @include mq(sm) {
          width: get_vw(16, $viewport-sm);
          height: get_vw(16, $viewport-sm);
        }
        &::before {
          width: get_vw(10);
          height: get_vw(10);
          font-size: get_vw(6);
          content: '●';
          line-height: 1;
          @include mq(md) {
            width: get_vw(8, $viewport-md);
            height: get_vw(8, $viewport-md);
            font-size: get_vw(6, $viewport-md);
          }
          @include mq(sm) {
            width: get_vw(16, $viewport-sm);
            height: get_vw(16, $viewport-sm);
            font-size: get_vw(12, $viewport-sm);
          }
        }
      }
    }
  }
}
