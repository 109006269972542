
.list-point {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    padding-left: get_vw(10);
    margin-bottom: get_vw(10);
    line-height: 1.3;
    letter-spacing: 0.02em;
    position: relative;
    @include mq(md) {
      padding-left: get_vw(10, $viewport-md);
      margin-bottom: get_vw(10, $viewport-md);
    }
    @include mq(sm) {
      padding-left: get_vw(10, $viewport-sm);
      margin-bottom: get_vw(10, $viewport-sm);
    }
    &::before {
      content: '';
      width: get_vw(4);
      height: get_vw(4);
      border-radius: get_vw(2);
      top: get_vw(6);
      display: inline-block;
      background-color: $color-text-default;
      position: absolute;
      left: 0;
      @include mq(md) {
        width: get_vw(4, $viewport-md);
        height: get_vw(4, $viewport-md);
        border-radius: get_vw(2, $viewport-md);
        top: get_vw(6, $viewport-md);
      }
      @include mq(sm) {
        width: get_vw(6, $viewport-sm);
        height: get_vw(6, $viewport-sm);
        border-radius: get_vw(3, $viewport-sm);
        top: get_vw(8, $viewport-sm);
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.list-none {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    padding-left: 0;
    margin-bottom: get_vw(10);
    line-height: 1.3;
    letter-spacing: 0.02em;
    position: relative;
    @include mq(md) {
      margin-bottom: get_vw(10, $viewport-md);
    }
    @include mq(sm) {
      margin-bottom: get_vw(10, $viewport-sm);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

ol {
  margin: 0;
  padding: 0;
  padding-left: get_vw(15);
  @include mq(md) {
    padding-left: get_vw(15, $viewport-md);
  }
  @include mq(sm) {
    padding-left: get_vw(20, $viewport-sm);
  }
  li {
    margin-bottom: get_vw(10);
    line-height: 1.3;
    letter-spacing: 0.02em;
    position: relative;
    @include mq(md) {
      margin-bottom: get_vw(10, $viewport-md);
    }
    @include mq(sm) {
      margin-bottom: get_vw(10, $viewport-sm);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

/*
---
name: Contents List
category: modules/list
---

```html
<div class="list-residents-item">
    <a href="/residents/detail.html">
        <div class="image-overlay image-current">
            <img src="/images/residents/resident-img1.png" alt="" class="gray">
        </div>
        <div class="list-residents-item-name">
            <span class="label label-inverse">Joke Raes</span>
        </div>
        <p class="list-residents-item-description">September - November 2017<br>Visual artist / British</p>
    </a>
</div>
```
*/
.list-contents {
  display: flex;
  flex-wrap: wrap;
  margin: 0 get_vw(-10);
  @include mq(md) {
    display: block;
    margin: get_vw(20, $viewport-md) 0;
  }
}
.list-contents-item {
  width: 33.3333%;
  padding: 0 get_vw(10);
  margin-bottom: get_vw(40);
  @include mq(md) {
    width: 100%;
    margin: get_vw(20, $viewport-md) 0;
    padding: 0;
  }
  @include mq(sm) {
    margin: get_vw(20, $viewport-sm) 0;
  }
  &-link {
    display: block;
    text-decoration: none;
    @include mq(md) {
      display: flex;
    }
  }
  &-image {
    height: get_vw(180);
    background-position: center;
    background-size: cover;
    @include mq(md) {
      width: 50%;
      height: get_vw(212, $viewport-sm);
    }
    @include mq(sm) {
      width: 50%;
      height: get_vw(256, $viewport-sm);
    }
  }
  &-info {
    @include mq(md) {
      padding-left: get_vw(15, $viewport-md);
      width: 50%;
    }
  }
  &-hr {
    border: 0;
    border-top: get_vw(1) solid $color-border-default;
    width: get_vw(30);
    margin: get_vw(20) 0;
    @include mq(sm) {
      margin: get_vw(20, $viewport-sm) 0;
    }
  }
  &-date {
    font-size: get_vw(12);
    margin: get_vw(20) 0 1vw;
    @include mq(md) {
      font-size: get_vw(12, $viewport-md);
      margin-top: 0;
    }
    @include mq(sm) {
      font-size: get_vw(20, $viewport-sm);
      margin-bottom: get_vw(20, $viewport-sm);
    }
  }
  .btn {
    margin: get_vw(20) 0 0;
    @include mq(sm) {
      display: none;
    }
  }
}

/*
---
name: Residents List
category: modules/list
---

```html
<div class="list-residents-item">
    <a href="/residents/detail.html">
        <div class="image-overlay image-current">
            <img src="/images/residents/resident-img1.png" alt="" class="gray">
        </div>
        <div class="list-residents-item-name">
            <span class="label label-inverse">Joke Raes</span>
        </div>
        <p class="list-residents-item-description">September - November 2017<br>Visual artist / British</p>
    </a>
</div>
```
*/

.list-residents {
  display: flex;
  flex-wrap: wrap;
  &.row {
    margin: 0 get_vw(-10);
    @include mq(md) {
      margin: 0 get_vw(-10, $viewport-md);
    }
    @include mq(sm) {
      margin: 0 get_vw(-10, $viewport-sm);
    }
  }
}
.list-residents-item {
  margin-top: get_vw(30);
  width: get_vw(160);
  @include mq(sm) {
    width: get_vw(323, $viewport-sm);
    margin-top: get_vw(40, $viewport-sm);
    margin-bottom: get_vw(40, $viewport-sm);
  }
  a {
    display: block;
    text-decoration: none;
  }
  &-image {
    width: 100%;
    height: get_vw(160);
    background-size: cover;
    background-position: center;
    @include mq(sm) {
      width: get_vw(323, $viewport-sm);
      height: get_vw(323, $viewport-sm);
    }
  }
  &-name {
    margin-top: get_vw(12);
    margin-bottom: get_vw(8);
    @include mq(md) {
      margin-top: get_vw(12, $viewport-md);
      margin-bottom: get_vw(8, $viewport-md);
    }
    @include mq(sm) {
      margin-top: get_vw(12, $viewport-sm);
      margin-bottom: get_vw(8, $viewport-sm);
    }
  }
  &:first-child {
    margin-top: 0;
  }
  &-description {
    text-decoration: underline;
    line-height: get_vw(16);
    font-size: get_vw(12);
    margin-top: get_vw(8);
    @include mq(md) {
      font-size: get_vw(12, $viewport-md);
      line-height: get_vw(16, $viewport-md);
      margin-top: get_vw(8);
    }
    @include mq(sm) {
      font-size: get_vw(12, $viewport-md);
      line-height: get_vw(32, $viewport-sm);
      margin-top: get_vw(8);
    }
  }
}
